<template>
    <div>
        <TopbarSaleIt titolo="IL MUSEO"/> 

        

        <div class="contenutoSala">          
            <img id="ingresso" src="@/assets/pianta_museale.png" alt="Pianta museale">
            <h3 class="titoloContenutoSala">VILLA ADELE già VILLA PIA</h3>
            
            <audio controls class="audio">
                <source src="@/assets/audio/museo.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">Nel 1615, il Cardinale Bartolomeo Cesi di Acquasparta, tesoriere generale della Camera Apostolica, 
                fece costruire ad Anzio una villa residenziale nel luogo detto della “Torre d’Imperio” (Soffredini 1879, 115). Nel 1648, 
                la villa fu venduta dal nipote, Giovanni Federico Cesi, al principe Camillo Pamphilj, nipote di Papa Innocenzo X (1644-1655), 
                che ne ampliò e abbellì gli edifici. Nel 1760 la proprietà della villa passò al ramo Doria Pamphilj a cui subentreranno prima 
                il casato Borghese, nel 1834, e poi quello Aldobrandini nel 1851. Dopo il parziale smembramento della tenuta, avvenuto nel 
                primo decennio del Novecento, il casino fu trasformato in albergo, l’“Excelsior Palace Hotel”. A questo periodo risale la 
                sistemazione definitiva del prospetto principale, con un portico a tre archi che permette di accedere alla cosiddetta Sala 
                delle Conchiglie, a pianta ottagonale. A partire dal 1964, l’intero comprensorio divenne proprietà del Comune di Anzio. 
                Attualmente, il parco è destinato a uso pubblico e il casino principale accoglie il Museo Civico Archeologico, dal 2002, 
                il Museo dello Sbarco, dal 1994, e la biblioteca comunale.
            </p>

            <div class="image-container">
                <img src="@/assets/ingresso.jpg" alt="Ingresso museo">
                <img src="@/assets/MUS_00001.jpg" alt="Museo">
                <img src="@/assets/MUS_00002.jpg" alt="Museo">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala1It',
        components: {
            TopbarSaleIt
        }
    }
</script>