<template>
    <div>
        <TopbarSaleEs titolo="SALA VII"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/7-Sala_VII.jpg" alt="Sala VII">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA VII</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/sala_siete.mp3" type="audio/mpeg">
            </audio>   
            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">En la sala hay un aceite de transporte, encontrado en el mar, del mismo tipo que el expuesto
             en la sala V.<br>
             En el suelo, tres inscripciones sepulcrales. Dos son fragmentarios, el primero con rastros de reelaboración para su uso con
             función diferente del bloque de mármol, el segundo se informa en la parte de una piedra. El tercero, en cambio, devolvió el texto completo
             y puede referirse a sujetos de condición libertina.<br><br>
             El elemento más importante de la sala es la copia del llamado <i>Gladiador Borghese</i>. La estatua, encontrada alrededor de 1609
             en Anzio, fue comprado por Napoleón en 1807 y destinado a enriquecer las colecciones del Museo del Louvre donde aún se encuentra
             preservado. Es obra del escultor Efesio Agasias y está fechado unánimemente alrededor del año 100 a.C.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/7-Sala_VII.jpg" alt="Sala VII">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEs from '@/components/TopbarSaleEs.vue';

    export default {
        name: 'Sala7Es',
        components: {
            TopbarSaleEs
        }
    }
</script>