<template>
    <div>
        <TopbarSaleEn titolo="HALL III"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/3-Sala_III.jpg" alt="Hall III">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">HALL III</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/hall_three.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">The room is dedicated to marble artefacts, most of which were once kept in the gardens 
                of the present municipal villa, formerly Doria Pamphilj.<br><br>
                On the left, on the wall, are several marble fragments related to three statuettes datable to the 
                2nd century AD: two, standing, can be identified, although fragmentary, with Artemis the huntress and one, seated, 
                possibly identifiable with Fortuna; also on display are several fragments of cinerary urns and sarcophagi 
                datable between the 1st and 3rd centuries AD. Placed on the ground is an anepigraphic funerary cippus.<br><br>
                On the shelf is the cinerary urn of Publius Sulpicius Peregrinus, dated to the Flavian era (second half of the 1st century AD). 
                It is a find made at Anzio in the locality of La Sacida in the 1940s, consisting of a stele with an inscription, currently kept 
                in the Museo Nazionale Romano in Rome, and the cinerary urn on display: rectangular, made of marble, missing the lid, which has 
                been lost, and which must have been attached by means of iron clamps.
                The artifact, made with elegance and skill, was supposed to collect the ashes of the deceased, born in <i>Mediolanum</i> and died
                at the age of 28, after nine years of military service in the <i>equites speculatores</i>, the mounted department of the corps
                of the Praetorians. It is presumable that Sulpicius Peregrino carried out part of his service at the imperial residence of
                Anzio, where he would have died.<br><br>
                On the right is a sarcophagus with strigilations framed by pilasters of the Corinthian order and in the center a tabula ansata without
                inscription or depiction. The lid, consistent in type, with anepigraphed tabula ansata is probably not pertinent.
                It is a typical product of urban marble workshops of the III-IV AD.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/3-Sala_III.jpg" alt="Sala III">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'Hall3En',
        components: {
            TopbarSaleEn
        }
    }
</script>