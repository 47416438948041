<template>
    <div>
        <TopbarInternaEs /> 

        <div class="infoSale">
            <img id="ingresso" src="@/assets/ingresso.jpg" alt="Ingresso museo">
            <router-link to="/es/museo"><p class="btnLingua">Descubre el museo</p></router-link>
            <img id="assonometria" src="@/assets/assonometria.jpg" alt="Mappa 3D">
            <p id="titoloSale">Las Salas</p>
        </div>
        
        <div class="sale">
            <div class="sala">
                <router-link to="/es/sala-1">
                    <img class="salaImg" src="@/assets/sale/1-sala_I.jpg" alt="Sala 1">
                    <p class="salaDes">I</p>
                </router-link>
            </div>     
            
            <div class="sala">
                <router-link to="/es/sala-2">
                    <img class="salaImg" src="@/assets/sale/2-sala_II.jpg" alt="Sala 2">
                    <p class="salaDes">II</p>
                </router-link>
            </div>

            <div class="sala">
                <router-link to="/es/sala-3">
                    <img class="salaImg" src="@/assets/sale/3-Sala_III.jpg" alt="Sala 3">
                    <p class="salaDes">III</p>
                </router-link>
            </div>
        </div>

        <div class="sale">
            <div class="sala">
                <router-link to="/es/sala-4">
                    <img class="salaImg" src="@/assets/sale/4-Sala_IV.jpg" alt="Sala 4">
                    <p class="salaDes">IV</p>
                </router-link>
            </div>     
            
            <div class="sala">
                <router-link to="/es/sala-5">
                    <img class="salaImg" src="@/assets/sale/5-Sala_V.jpg" alt="Sala 5">
                    <p class="salaDes">V</p>
                </router-link>
            </div>

            <div class="sala">
                <router-link to="/es/sala-6">
                    <img class="salaImg" src="@/assets/sale/6-Sala_VI.jpg" alt="Sala 6">
                    <p class="salaDes">VI</p>
                </router-link>
            </div>
        </div>

        <div class="sale">
            <div class="sala">
                <router-link to="/es/sala-7">
                    <img class="salaImg" src="@/assets/sale/7-Sala_VII.jpg" alt="Sala 7">
                    <p class="salaDes">VII</p>
                </router-link>
            </div>     
            
            <div class="sala">
                <router-link to="/es/sala-8">
                    <img class="salaImg" src="@/assets/sale/8-Sala_VIII.jpg" alt="Sala 8">
                    <p class="salaDes">VIII</p>
                </router-link>
            </div>

            <div class="sala">
                <router-link to="/es/sala-9">
                    <img class="salaImg" src="@/assets/sale/9-Sala_IX.jpg" alt="Sala 9">
                    <p class="salaDes">IX</p>
                </router-link>
            </div>
        </div>

        
    </div>
</template>

<script>
    import TopbarInternaEs from '@/components/TopbarInternaEs.vue';

    export default {
        name: 'EsHome',
        components: {
            TopbarInternaEs
        }
    }
</script>