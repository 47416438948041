<template>
    <div>
        <TopbarSaleEn titolo="HALL VIII"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/8-Sala_VIII.jpg" alt="Sala VIII">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">HALL VIII</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/hall_eight.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Listen to the audio track</i></p>
            <p class="desContenutoSala">The hall displays documents, drawings and photographs that illustrate the richness of the
             existing documentation on the antiquities of Anzio. The real "Luck of Anzio".
             <br><br>
             On the left panel are documents and insights relating to important finds or archaeological sites of Anzio and of
             surroundings. In particular, some events related to the purchase of the Fanciulla d'Anzio by the Italian state with the first
             page of a newspaper of the time; archival documents relating to the construction projects of beacons and lanterns on the ruins of the port
             neronian; the photographs of the Astura tower transformed into the film set of the film Cleopatra, with Richard Burton and Liz Taylor.
             <br><br>
             On the nearby wall is the photograph taken by the Civil Engineers in 1909 to be attached to the assessment of the girl from Anzio
             where the exact point of discovery is reported.
             <br><br>
             On the right panel are displayed some documents, drawings and photographs relating to the history of the discoveries made
             in the area of the imperial villa, from the drawings published by Volpi and other scholars during the eighteenth century, to the reliefs
             by Rodolfo Lanciani, to the discoveries made in the first decades of the twentieth century, such as the pre-Caesarian calendar
             and the statue of an Amazon on horseback who overwhelms a barbarian currently kept in Rome in the Roman National Museum.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/8-Sala_VIII.jpg" alt="Sala VIII">
            </div>
            
            
            <h3 class="titoloContenutoSala">FINDS</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/finds.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">Some exhibits are also exhibited in the hall. <br>
             1) Part of an arm in white marble, datable to the imperial age and relating to a larger-than-life statue; <br>
             2) imperial praetorian; <br>
             3-4) on celestial supports: fragmentary sepulchral inscriptions; <br>
             5-6) on the ground is another sepulchral inscription, mutilated, and part of a significant inscription reporting the construction
             of a public work, where a via tecta, i.e. covered, and the forum are mentioned. Unfortunately, the circumstances and the place of the
             findings are based on uncertain information. Furthermore, the piece was certainly resected and reused as a threshold already in
             ancient by moving it from the place where it was originally placed.
            </p>
        </div>      
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'Hall8En',
        components: {
            TopbarSaleEn
        }
    }
</script>