<template>
    <div>
        <TopbarSaleEs titolo="SALA V"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/5-Sala_V.jpg" alt="Sala V">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA V<br>EL NINFEO DE H&Eacute;RCULES</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/ninfeo.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">Uno de los artefactos más importantes encontrados en Anzio es el nicho con una representación en mosaico de Hércules.
             La hornacina, datable en época julio-claudia, mediados del siglo I AC, fue encontrado en 1930 durante los trabajos de demolición de estructuras de
             época imperial romana realizada en piazza del fontanile, al pie de la colina sobre la que se asienta Villa Corsini, actual sede del
             Municipio de Anzio.<br>
             En la representación principal aparece Hércules, semi-reclinado y apoyado en una roca, con la cabeza rodeada por una corona
             probablemente hiedra; el héroe borracho se apoya en su garrote con la mano derecha y sostiene un <i>skyphos</i>, una copa para beber,
             en la mano izquierda. A los lados, a la izquierda, la atlética figura de un joven, desnudo y de pie; a la derecha un cerdo frente a un
             gruta. El panel está rodeado de decoraciones compuestas por hileras de marcos de mosaico, incluida una teoría falsa de palmetas
             roca (imitación realizada con incrustaciones calcáreas) y recurrencias de conchas, según un gusto típico de finales de julio
             claudia y en especial neroniana.<br>
             Las circunstancias del hallazgo dificultan la interpretación funcional del complejo, que se cree que es un ninfeo
             (una fuente monumental) o una capilla. Es probable que sea un lugar de ociosidad donde poder resguardarse del calor del verano,
             colocado en una villa o jardín.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/10-ercole.jpg" alt="Il Ninfeo di Ercole">
            </div>          
            
            <h3 class="titoloContenutoSala">ANZIO Y EL MAR</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/sala_cinco.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">
                A la entrada de la sala V, a la izquierda, hay un gran dolio para el transporte de alimentos.
                 Este tipo de contenedor se colocaba en la sentina de los barcos directamente durante la construcción
                 de los barcos. Las fábricas más importantes de este tipo de dolio se encuentran en la colonia romana de Minturnae
                 (ahora Minturno, en la desembocadura del Liri-Garigliano).<br><br>
                 A la derecha se encuentran algunas de las ánforas de transporte pertenecientes a los tipos más comunes y extendidos,
                 de producción italiana, española y africana. Provienen de hallazgos submarinos y terrestres y somos testigos de la
                 entramado de relaciones comerciales entre la época tardorrepublicana y la imperial.<br><br>
                 Continuando, en la repisa del suelo, se encuentra un bloque de ancla de plomo de época romana; en el panel es parte de un relieve
                 mármol con representación de un delfín. Otro importante descubrimiento submarino, realizado a pocos kilómetros
                 al norte de Anzio, se encuentra el torso de una estatua masculina en mármol blanco (finales del siglo I d. C. - primera mitad del siglo II d. C.).<br><br>
                 Al lado forma parte de un soporte en forma de herma con cabeza barbada. Una estatua apoyada en este elemento,
                 tal vez un Dionisio borracho, del que quedan parte de su brazo y capa. Siglo II dC<br><br>
                 Cerca del pilar central de la sala hay un modelo reconstructivo del puerto de Nerón con un panel que lo ilustra
                 la técnica constructiva de los muelles del puerto.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/11-Anfore.jpg" alt="Anfore">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEs from '@/components/TopbarSaleEs.vue';

    export default {
        name: 'Sala5Es',
        components: {
            TopbarSaleEs
        }
    }
</script>