<template>
    <div>
        <TopbarSaleFr titolo="SALLE VIII"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/8-Sala_VIII.jpg" alt="Salle VIII">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALLE VIII</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_8.1.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">La salle présente des documents, des dessins et des photographies illustrant la richesse de la 
            documentation existante sur les antiquités d'Anzio. La véritable "Fortune d'Anzio".
            <br><br>
            Sur le panneau de gauche se trouvent des documents et des études approfondies sur des découvertes archéologiques ou des sites importants à 
            Anzio et dans ses environs. En particulier, certains événements liés à l'achat de la Fanciulla d'Anzio par l'État italien avec la première 
            page d'un journal de l'époque; des documents d'archives relatifs aux plans pour la construction de mede et de lanternes sur les ruines du 
            port de Néron; des photographies de Torre Astura transformée en plateau de tournage du film Cléopâtre, avec Richard Burton et Liz Taylor.
            <br><br>
            Sur le mur voisin se trouve la photographie prise par les ingénieurs des Ponts et Chaussées en 1909 pour être jointe à l'évaluation de la jeune 
            fille d'Anzio, qui montre l'emplacement exact de la découverte.
            <br><br>
            Le panneau de droite présente des documents, des dessins et des photographies relatifs à l'histoire des découvertes effectuées dans la zone de la 
            villa impériale, depuis les dessins publiés par Volpi et d'autres chercheurs au cours du XVIIIe siècle, jusqu'aux reliefs du XIXe siècle réalisés 
            par Rodolfo Lanciani, en passant par les découvertes effectuées au cours des premières décennies du XXe siècle, comme le calendrier précésarien et 
            la statue d'une amazone à cheval balayant un barbare, actuellement conservée au Museo Nazionale Romano de Rome.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/8-Sala_VIII.jpg" alt="Salle VIII">
            </div>
            
            
            <h3 class="titoloContenutoSala">LES DÉCOUVERTES</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_8.2.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">Un certain nombre de pièces sont également exposées dans la salle.<br>
            1) Partie d'un bras en marbre blanc, datant de l'époque impériale et se rapportant à une statue plus grande que nature;<br>
            2) prétorien impérial; <br>
            3-4) sur les supports célestes: inscriptions sépulcrales fragmentaires; <br>
            5-6) au sol, une autre inscription sépulcrale, mutilée, et une partie d'une inscription significative signalant la construction d'un ouvrage public, 
            où il est fait mention d'une via tecta, c'est-à-dire d'une rue couverte et d'un forum. Malheureusement, les circonstances et la localisation de la 
            découverte sont basées sur des informations incertaines. De plus, la pièce a certainement été restaurée et réutilisée comme seuil dans l'Antiquité 
            en la déplaçant de son emplacement d'origine.
            </p>
        </div>      
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'Salle8Fr',
        components: {
            TopbarSaleFr
        }
    }
</script>