<template>
    <div>
        <TopbarSaleEn titolo="HALL V"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/5-Sala_V.jpg" alt="Hall V">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">HALL V<br>THE NYMPHAEUM OF ERCULES</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/nymphaeum.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">One of the most important artifacts found at Anzio is the niche with a mosaic depiction of Hercules.
             The niche, datable to the Julio-Claudian period, mid-1st century BC, was found in 1930 during demolition works of structures of
             imperial Roman age carried out in piazza del fontanile, at the foot of the hill on which Villa Corsini, the current seat of the
             Municipality of Anzio.<br>
             In the main depiction Hercules appears, semi-reclining and leaning against a rock, with his head surrounded by a crown
             probably ivy; the drunken hero leans on his club with his right hand and holds a <i>skyphos</i>, a drinking cup,
             in the left hand. On the sides, on the left, the athletic figure of a young man, naked and standing; to the right a pig in front of a
             grotto. The panel is surrounded by decorations made up of courses of mosaic frames, including a fake theory of palmettes
             rock (imitation made with calcareous incrustations) and recurrences of shells, according to a typical taste of the late julius age
             claudia and in particular neroniana.<br>
             The circumstances of the discovery make the functional interpretation of the complex, believed to be a nymphaeum, difficult
             (a monumental fountain) or a chapel. It is likely that it is a place of idleness where you can take shelter from the summer heat,
             placed in a villa or garden.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/10-ercole.jpg" alt="Il Ninfeo di Ercole">
            </div>          
            
            <h3 class="titoloContenutoSala">ANZIO AND THE SEA</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/hall_five.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">
                At the entrance to room V, on the left, is a large dolium for the transport of foodstuffs.
                 This type of container was placed in the bilge of the boats directly during construction
                 of the ships. The most important factories of this type of dolium were found in the Roman colony of Minturnae
                 (now Minturno, at the mouth of the Liri-Garigliano).<br><br>
                 To the right are some of the transport amphorae belonging to the most common and widespread types,
                 of Italian, Spanish and African production. They come from submarine and land finds and we bear witness to the
                 network of commercial relations between the late republican and imperial age.<br><br>
                 Continuing, on the shelf on the ground, is a block of lead anchor from the Roman age; on the panel is part of a relief
                 marble with representation of a dolphin. Another important submarine discovery, made a few kilometers away
                 north of Anzio, is the torso of a male statue in white marble (end of the 1st century AD - first half of the 2nd century AD).<br><br>
                 Alongside is part of a support in the shape of a herm with a bearded head. A statue leaned against this element,
                 perhaps a drunken Dionysus, of which part of his arm and cloak remain 2nd century AD.<br><br>
                 Near the central pillar of the room is a reconstructive model of Nero's port with a panel illustrating it
                 the construction technique of the docks of the port.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/11-Anfore.jpg" alt="Anfore">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'Hall5En',
        components: {
            TopbarSaleEn
        }
    }
</script>