<template>
    <div>
        <TopbarSaleEn titolo="HALL VII"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/7-Sala_VII.jpg" alt="Hall VII">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">HALL VII</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/hall_seven.mp3" type="audio/mpeg">
            </audio>   
            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">In the room there is a transport oil, found in the sea, of the same type as the one on display
             in room V.<br>
             On the ground, three sepulchral inscriptions. Two are fragmentary, the first with traces of rework for use with
             different function of the block of marble, the second is reported on part of a stone. The third instead returned the complete text
             and can be referred to subjects of libertine condition.<br><br>
             The most important element of the room is the copy of the so-called <i>Borghese Gladiator</i>. The statue, found around 1609
             in Anzio, it was purchased by Napoleon in 1807 and destined to enrich the collections of the Louvre Museum where it is still
             preserved. It is the work of the Ephesian sculptor Agasias and is unanimously dated to around 100 BC.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/7-Sala_VII.jpg" alt="Sala VII">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'Hall7En',
        components: {
            TopbarSaleEn
        }
    }
</script>