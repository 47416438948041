<template>
    <div>
        <TopbarSaleEs titolo="SALA IV"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/4-Sala_IV.jpg" alt="Sala IV">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA IV</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/sala_cuatro.mp3" type="audio/mpeg">
            </audio>            
            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">
                La sala exhibe objetos relacionados con diversos aspectos de la vida cotidiana en Anzio en la época imperial romana.
                 <br><br>
                 En la vitrina de la izquierda se encuentran algunos fragmentos de material arquitectónico de ladrillo del siglo I d.C.,
                 destinado a cubrir techos y arquitrabes de edificios o tumbas monumentales: una losa con cabeza de Gorgona; un fragmento
                 de losa de revestimiento arquitectónico con parte de figura masculina y escudo, con restos del color original;
                 una antefija de palmeta. Algunos fragmentos de yeso pintado se pueden referir a la misma zona.<br>
                 Por otro lado, el gran mortero de terracota con sello de fábrica se puede referir a actividades de cocina
                 <i>Augustalis Saturnini</i> del siglo I d.C.

            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_4.jpg" alt="Vetrina sala IV">
            </div>      
            
            <p class="desContenutoSala">
                En la vitrina de la derecha se muestran: en el estante superior, un pequeño espejo de plomo del siglo I d.C. y algunos jarrones en
                 bronce de la edad imperial. Dos jarras y una sítula con apliques de máscaras de Silenus.<br>
                 En el estante central hay varios fragmentos de vidrio de ventana, algunas monedas de bronce, incluidos dos sestercios
                 de la época imperial, y algunos hallazgos particulares:<br><br>
                 - dos <i>tabellae defixionum</i>, es decir, tablas de plomo en las que se grabaron frases insultantes y reales
                 maldiciones dirigidas a una persona odiada o enemiga. Son objetos típicos del ámbito competitivo vinculado a los juegos
                 artistas de circo que a menudo desean los peores males a los aurigas o incluso a los caballos de los equipos contrarios;<br>
                 - otro objeto particular es el <i>signaculum</i> o sello de bronce. Se utilizaba para dejar una impresión antes de cocinar,
                 verdadero sello distintivo en algunos productos alimenticios, como el pan;<br>
                 - finalmente, parte de un plato de una producción definida como gala meridional, fechada en el siglo I d.C. de un tipo bastante
                 raro que tiene las superficies cubiertas con un barniz que le da un efecto cromático particular llamado jaspeado.<br><br>
                 En el estante más bajo hay parte de un tubo, una fístula de plomo, con la inscripción en relieve del nombre del <i>plumbarius</i> que
                 en la época imperial lo creó: <i>Alexander fecit</i>.     
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_5.jpg" alt="Vetrina sala IV">
            </div> 
        </div>       
        
                
    </div>
</template>

<script>
    import TopbarSaleEs from '@/components/TopbarSaleEs.vue';

    export default {
        name: 'Sala4Es',
        components: {
            TopbarSaleEs
        }
    }
</script>