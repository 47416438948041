<template>
    <div>
        <TopbarSaleIt titolo="SALA I"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/1-sala_I.jpg" alt="Sala I">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA I <br>LE ORIGINI ANTICHE DELLA CITTA’ - ANTIUM LATINA (MATERIALE DEL X-IX SEC A.C.)</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_uno.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">Le prime testimonianze di occupazione stabile da parte dei Latini dell’area di Anzio risalgono al X sec a.C.; 
                tra la fine del VI e l’inizio del V sec. a.C. il Lazio meridionale è investito dall’invasione dei Volsci, 
                popolazione di stirpe osco-umbra: Anzio, subito conquistata, diventa nel V secolo una temibile avversaria di Roma. 
                La potenza volsca tramonta definitivamente nella seconda metà del IV sec a.C.<br>
                A partire da questo periodo si afferma il predominio romano: Anzio, come il resto del Lazio e della Campania settentrionale, 
                viene conquistata da Roma nel 338 a.C. Persa l’autonomia, la città accoglie la deduzione di una colonia formata da 300 
                cittadini forniti dei pieni diritti di cittadinanza romana. Pecco piccolo nucleo di coloni doveva difendere il porto della 
                città e la strada che conduceva verso i Colli Albani. <br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/1-sala_I.jpg" alt="Sala I">
            </div>     
            
            <p class="desContenutoSala">
                Nella vetrina di sinistra sono esposti vasellame ceramico e oggetti di bronzo databili tra il X e il VII sec a.C.: tazze, olle 
                e orcioli di impasto grezzo bruno e fibule a navicella in bronzo. <br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_1.jpg" alt="Vetrina sala I">
            </div>

            <p class="desContenutoSala">
                Nella vetrina di destra troviamo materiali rinventi probabilmente in un deposito votivo di età medio-repubblicana (IV-III sec. a.C.), ovvero materiali utilizzati o offerti in un luogo di culto. Si tratta di piccole brocche di impasto chiaro, ciotole di ceramica a vernice nera e alcuni vasi a vernice nera sovradipinta provenienti in gran parte dall’Etruria.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_2.jpg" alt="Vetrina sala II">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala1It',
        components: {
            TopbarSaleIt
        }
    }
</script>