<template>
  <div class="topbar">
    <img id="logoScritta" src="@/assets/logo_scritta.png" alt="Logo">
  </div>
</template>

<script>
export default {
  name: 'TopBar'
}
</script>
