<template>
    <div>
        <TopbarSaleIt titolo="SALA II"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/2-sala_II.jpg" alt="Sala II">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA II</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_due.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">Sulla sinistra, è la vetrina 3, dedicata alle testimonianze archeologiche di età medio e 
                tardo repubblicana (IV-II sec. a.C.). Si tratta, per la maggior parte, di statuette e parti anatomiche di carattere 
                votivo. Da segnalare il frammento di ceramica a vernice nera sovradipinta, con lettere dipinte relative alla parte 
                terminale di un teonimo, forse Fortuna, principale divinità venerata ad Anzio, nella forma Fortune, ovvero “alla Fortuna” 
                o “della Fortuna”.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_3.jpg" alt="Vetrina sala II">
            </div>
            
            <p class="desContenutoSala">
                Di particolare rilievo è il singolare <i>Guttus</i>, antico biberon, di ceramica a vernice nera in forma di elefante, 
                montato dal cornac, risalente al III sec a.C.
                Il vaso fu rinvenuto ad Anzio nel 1954 nel corso della costruzione di una palazzina in via Roma. Si tratta di un <i>unicum</i> 
                nel suo genere in ambito italico. La cura del dettaglio e la movenza dell’animale assicura la conoscenza diretta del 
                soggetto da parte del ceramista, forse apulo. Nello spazio tra le zampe anteriori e quelle posteriori, è inserita a 
                rilievo una schiera di armati con lance e scudi che avanzano ai fianchi dell’elefante, qualificandone la funzione militare.
                <br><br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_guttus.jpg" alt="Sala II">
            </div>
            

            <h3 class="titoloContenutoSala">LA FANCIULLA D'ANZIO</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fanciulla.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">
                La statua fu rinvenuta il 20 dicembre del 1878, a causa di una forte mareggiata, in una nicchia della villa imperiale. Collocata inizialmente nell’androne di Villa Sarsina, residenza dei principi Aldobrandini, poiché era stata rinvenuta nella loro proprietà, fu in seguito acquistata nel 1909 dallo Stato italiano per la considerevole cifra, per quei tempi, di 450.000 lire.
                La statua raffigura un personaggio femminile, poco più che adolescente, che Indossa il chitone e un mantello piuttosto pesante, portato annodato sui fianchi; calza dei semplici sandali e porta i capelli raccolti secondo un’inconsueta acconciatura.
                Le mani sorreggono un vassoio con sopra alcuni oggetti: un ramoscello e bacche di alloro, una benda sacrificale, due zampe leonine attribuibili ad un contenitore.
                Alcuni dettagli tecnici e la rilavorazione del plinto di base hanno fatto ipotizzare che facesse parte di un gruppo statuario, forse riferibile ad ambito dionisiaco. Si tratterebbe di un originale ellenistico databile al III sec. a.C.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/fanciulla.jpg" alt="Statua Fanciulla d'Anzio">
            </div>

            <h3 class="titoloContenutoSala">L'ET&Agrave; ROMANA</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_due_eta_romana.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">
                Sul ripiano a destra è parte di un rilievo, databile all’inizio del III secolo, dove è rappresentata una scena di 
                sacrificio con sullo sfondo architetture di ambito sacro con capitelli di tipo egittizzante.
                Sulla parete, a destra, un frammento di sarcofago di età imperiale con rappresentazione di un cavallo che tira 
                un carro. A sinistra, due iscrizioni sepolcrali: in alto <i>“Clodia Ge(mella), liberta di Gaio, fece per se e per sua 
                sorella Clodia Gemella, liberta di Gaio”</i> (fine I-inizi II sec d.C.); in basso: Agli Dei Mani di Successus, schiavo 
                imperiale (fine I sec d.C.).
            </p>

            <div class="image-container">
                <img src="@/assets/sale/2-sala_II.jpg" alt="Sala II">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala2It',
        components: {
            TopbarSaleIt
        }
    }
</script>