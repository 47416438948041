<template>
    <div>
        <TopbarSaleFr titolo="SALLE III"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/3-Sala_III.jpg" alt="Salle III">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALLE III</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_3.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">La salle est consacrée aux objets en marbre, dont la plupart étaient autrefois conservés dans 
                les jardins de l'actuelle villa municipale, anciennement Doria Pamphilj.<br><br>
                À gauche, sur le mur, sont exposés plusieurs fragments de marbre qui peuvent être rattachés à trois statuettes datant du IIe siècle 
                après J.-C. : deux, debout, peuvent être identifiées, bien que de façon fragmentaire, à Artémis la chasseresse et une, assise, peut 
                peut-être être identifiée à la Fortune ; sont également exposés plusieurs fragments d'urnes cinéraires et de sarcophages datant du 
                Ier et du IIIe siècle après J.-C. Posé sur le sol, un cippus funéraire anépigraphe.<br><br>
                Sur l'étagère se trouve l'urne cinéraire de Publius Sulpicius Peregrinus, datée de l'époque flavienne (seconde moitié du Ier 
                siècle après J.-C.). Il s'agit d'une découverte faite à Anzio dans la localité de La Sacida dans les années 1940, composée d'une 
                stèle avec une inscription, actuellement conservée au Museo Nazionale Romano de Rome, et de l'urne cinéraire exposée : rectangulaire, 
                en marbre, sans le couvercle, qui a été perdu, et qui devait être fixé à l'aide de colliers en fer. L'objet, réalisé avec élégance 
                et habileté, a dû recueillir les cendres du défunt, né à <i>Mediolanum</i> et mort à l'âge de 28 ans, après avoir servi pendant neuf ans 
                dans les <i>equites speculatores</i>, une division montée du corps prétorien. On suppose que Sulpicius Peregrinus a effectué une partie 
                de son service à la résidence impériale d'Anzio, où il serait mort.<br><br>
                Sur la droite, un sarcophage avec des strigiles encadrés par des pilastres d'ordre corinthien et, au centre, une tabula ansata sans 
                inscription ni représentation. Le couvercle, de même type, avec une tabula ansata anépigraphe, n'est probablement pas pertinent. Il 
                s'agit d'un produit typique des ateliers de marbre urbains des IIIe et IVe siècles de notre ère.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/3-Sala_III.jpg" alt="Salle III">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'Salle3Fr',
        components: {
            TopbarSaleFr
        }
    }
</script>