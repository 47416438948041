<template>
    <div>
        <TopbarSaleIt titolo="SALA V"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/5-Sala_V.jpg" alt="Sala V">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA V<br>IL NINFEO DI ERCOLE</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/ninfeo.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">Uno dei manufatti più importanti rinvenuti ad Anzio è la nicchia con raffigurazione a mosaico di Ercole.
            La nicchia, databile ad età giulio-claudia, metà del I sec. a.C., fu rinvenuta nel 1930 durante opere di demolizione di strutture di 
            età romana imperiale effettuate in piazza del fontanile, ai piedi del rilievo su cui è costruita Villa Corsini, attuale sede del 
            Comune di Anzio.<br>
            Nella raffigurazione principale appare Ercole, semi sdraiato e appoggiato ad una roccia, con la testa cinta di una corona 
            verosimilmente d’edera; l’eroe, ebbro, è appoggiato con la mano destra alla clava e regge uno <i>skyphos</i>, una coppa per bere, 
            nella mano sinistra. Ai lati, a sinistra la figura atletica di un giovane, nudo e stante; a destra un suino di fronte ad una 
            grotta. Il pannello è circondato da decorazioni composte da ricorsi di cornici a mosaico, tra cui una teoria di palmette, finta 
            roccia (imitazione realizzate con incrostazioni calcaree) e ricorsi di conchiglie, secondo un gusto tipico dell’età tardo giulio 
            claudia ed in particolare neroniana.<br>
            Le circostanze del rinvenimento rendono difficile l’interpretazione funzionale del complesso, ritenuto un ninfeo 
            (una fontana monumentale) o un sacello. È probabile che si tratti di un luogo di ozio dove riparare dalla calura estiva, 
            inserito in una villa o giardino.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/10-ercole.jpg" alt="Il Ninfeo di Ercole">
            </div>          
            
            <h3 class="titoloContenutoSala">ANZIO E IL MARE</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_cinque.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">
                All’ingresso della sala V, sulla sinistra, è un grande dolio per il trasporto delle derrate alimentari. 
                Questa tipologia di contenitore veniva posizionato nella sentina delle imbarcazioni direttamente durante la costruzione 
                delle navi. Le fabbriche più importanti di questa tipologia di dolio si trovavano nella colonia romana di Minturnae 
                (attuale Minturno, alla foce del Liri-Garigliano).<br><br>
                Verso destra sono esposte alcune tra le anfore da trasporto appartenenti alle tipologie più comuni e diffuse, 
                di produzione italica, spagnola e africana. Provengono da ritrovamenti sottomarini e terrestri e testimoniamo la 
                rete di relazioni commerciali tra età tardo repubblicana e età imperiale.<br><br>
                Proseguendo, sul ripiano a terra, è un ceppo di ancora in piombo di età romana; sul pannello è parte di un rilievo 
                marmoreo con rappresentazione di un delfino. Altro ritrovamento sottomarino di rilievo, effettuato pochi chilometri 
                a nord di Anzio, è il torso di statua maschile in marmo bianco (fine I sec d.C. - prima metà II sec d.C.).<br><br>
                A fianco è parte di un sostegno a forma di erma con testa barbata. A questo elemento si appoggiava una statua, 
                forse Dioniso ebbro, di cui rimane parte del braccio e del mantello. II secolo d.C.<br><br>
                Presso il pilastro centrale della sala è un plastico ricostruttivo del porto neroniano con un pannello che illustra 
                la tecnica costruttiva dei moli del porto.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/11-Anfore.jpg" alt="Anfore">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala5It',
        components: {
            TopbarSaleIt
        }
    }
</script>