<template>
    <div>
        <TopbarSaleEs titolo="SALA VI"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/6-Sala_VI.jpg" alt="Sala VI">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA VI "ROBERTO CONFORTI"</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/sala_seis.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">A la entrada en dos lados: capiteles de varios tipos y una estatua sin cabeza de Artemisa que data del siglo II A.D.
                 A lo largo de las paredes se pueden admirar 5 paneles de yeso pintado, datables en el siglo II-III. AD, encontrado como resultado de
                 un deslizamiento de tierra en el área de la villa imperial de Anzio en 1970. La representación es de tipo jardín, con la representación
                 de un paisaje ocupado por una exuberante flora habitada por diferentes especies de aves, observadas desde un balcón bordeado
                 por un parapeto enrejado ligero. Es posible que el complejo en el que se encontraban fuera un lugar de descanso o un ninfeo.
                 Los yesos fueron retirados para asegurar su conservación y solo parcialmente han sido restaurados y conservados en Anzio. Para 
                 por ello la posición de los elementos individuales no corresponde a la original, articulada en un alto muro sobre el que se apoya
                 alternativamente abrían nichos rectilíneos y curvilíneos.<br><br>
                 En la pared derecha se encuentran las reproducciones de los llamados <i>Arae Ventorum</i> (altares de los vientos), hallados en Anzio y
                 conservado en los Museos Capitolinos. Se trata de tres altares votivos en forma de columna, con mención de los vientos y añadido del
                 reproducción de la tribuna de los navíos de guerra, la espuela de metal con la que se armaba la punta de los navíos de guerra para destripar
                 las naves enemigas, presa romana en la batalla de Astura en el 338 aC.<br><br>
                 En el centro de la sala se encuentra uno de los descubrimientos más importantes realizados en el mar de Anzio en los últimos años se trata de un
                 estatua masculina sin cabeza, de mármol negro grisáceo, con tahalí, la banda transversal sobre el pecho, rematada en patas de feroz.
                 Este elemento podría sugerir que se trata de la representación de un personaje del círculo dionisiaco o de
                 el propio Dionisio. Primera mitad del siglo II dC.<br><br>
                 En la sala VI, en las bases del suelo, se exhiben algunos artefactos de mármol de varios tipos y procedencias. Del mar de Anzio
                 proceden los cuatro capiteles compuestos con hojas de agua de finales del siglo I – principios del II d.C. y los dos 
                 elementos arquitectónicos con series de dientes y óvulos (finales del siglo I – mediados del siglo II d.C.).<br>
                 La base de una estatua drapeada, probablemente femenina, del siglo II a. C. no tiene indicios seguros de procedencia D.C. y
                 la base de una estatua masculina con pies calzados y dos puntales, uno de los cuales consiste en una coraza, que data del siglo I BC.<br>
                 A estos hallazgos hay que sumar el fragmento de un suelo de mosaico recuperado en el Arco del Muto en la zona de la villa imperial.
                 Probablemente pueda fecharse en la época Severa (primeras décadas del siglo III d.C.)<br><br>
                 En los tableros de las mesas se exhiben fragmentos de varios tipos pertenecientes a estatuas o relieves de mármol de la época imperial.<br>
                 En la mesa de la izquierda: parte de relieve con cornucopia, busto masculino con parte de la capa y busto masculino con coraza
                 a la égida.<br>
                 En la mesa de la derecha: acroterion angular (siglo II dC), soporte de estatua con decoración vegetal; figura masculina con tenia.<br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/6-Sala_VI.jpg" alt="Sala VI">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEs from '@/components/TopbarSaleEs.vue';

    export default {
        name: 'Sala6Es',
        components: {
            TopbarSaleEs
        }
    }
</script>