<template>
    <div>
        <TopbarSaleIt titolo="SALA IV"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/4-Sala_IV.jpg" alt="Sala IV">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA IV</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_quattro.mp3" type="audio/mpeg">
            </audio>            
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">
                Nella sala sono esposti oggetti riferibili a diversi aspetti della vita quotidiana ad Anzio in età romana imperiale.
                <br><br>
                Nella vetrina di sinistra sono esposti alcuni frammenti di materiale architettonico in laterizio del I sec. d.C., 
                destinato a ricoprire tetti e architravi di edifici o tombe monumentali: una lastra con testa di Gorgone; un frammento 
                di lastra di rivestimento architettonico con parte di figura maschile e scudo, con ancora tracce del colore originale; 
                una antefissa a palmetta. Allo stesso ambito sono riferibili alcuni frammenti di intonaco dipinto.<br>
                Alle attività di cucina è invece riferibile il grande mortaio di terracotta con bollo di fabbrica 
                <i>Augustalis Saturnini</i> del I sec d.C.

            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_4.jpg" alt="Vetrina sala IV">
            </div>      
            
            <p class="desContenutoSala">
                Nella vetrina di destra sono esposti: sul ripiano più alto, uno specchietto in piombo del I sec d.C. e alcuni vasi in 
                bronzo di età imperiale. Due brocche e una situla con appliques a maschera di sileno.<br>
                Sul ripiano mediano sono esposti diversi frammenti di vetro per finestra, alcune monete di bronzo, tra cui due sesterzi 
                di età imperiale, e alcuni reperti particolari:<br><br>
                - due <i>tabellae defixionum</i>, ovvero tabelle in piombo sulle quali erano incise delle frasi ingiuriose e delle vere e proprie 
                maledizioni rivolte ad una persona odiata o nemica. Si tratta di oggetti tipici dell’ambito agonistico legato ai giochi 
                circensi che spesso augurano i mali peggiori agli aurighi o addirittura ai cavalli delle squadre avverse;<br>
                - altro oggetto particolare è il <i>signaculum</i> o timbro in bronzo. Veniva utilizzato per lasciare un’impronta prima della cottura, 
                vero e proprio marchio di garanzia su alcuni prodotti alimentari, come ad esempio il pane;<br>
                - infine, parte di un piatto di una produzione definita sigillata sud gallica, datata al I sec. d.C. di un tipo piuttosto 
                raro che ha le superfici rivestite da una vernice che restituisce un effetto cromatico particolare detto marmorizzato.<br><br>
                Sul ripiano più basso è parte di una tubatura, una fistula in piombo, con l’iscrizione a rilievo del nome del <i>plumbarius</i> che 
                in età imperiale la realizzò: <i>Alexander fecit</i>.      
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_5.jpg" alt="Vetrina sala IV">
            </div> 
        </div>       
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala4It',
        components: {
            TopbarSaleIt
        }
    }
</script>