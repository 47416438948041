<template>
    <div>
        <TopbarSaleEs titolo="SALA III"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/3-Sala_III.jpg" alt="Sala III">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA III</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/sala_tres.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">La sala está dedicada a los hallazgos de mármol, en gran parte conservados en el pasado en los jardines
                 del actual parque municipal, antes Doria Pamphilj.<br><br>
                 A la izquierda, en la pared, varios fragmentos de mármol referentes a tres estatuillas datables en
                 siglo II AD: dos, de pie, identificables, aunque fragmentariamente, con Artemisa la cazadora y uno, sentado,
                 quizás identificable con Fortune; también se exhiben varios fragmentos de urnas cinerarias y sarcófagos
                 datable entre los siglos I y III d.C. Un cipo funerario sin epigrafiar colocado en el suelo.<br><br>
                 En la estantería se encuentra la urna cineraria de Publius Sulpicius Peregrino, datada en época Flavia (segunda mitad del siglo I d.C.).
                 Es un hallazgo realizado en Anzio en la localidad de La Sacida en la década de 1940, constituido
                 de una estela con una inscripción, actualmente conservada en Roma en el Museo Nacional Romano, y de la urna expuesta:
                 rectangular, de mármol, al que le falta la tapa, hoy perdida, que hubo que enganchar mediante garfios de hierro.
                 El artefacto, elaborado con elegancia y destreza, debía recoger las cenizas de los difuntos, nacidos en <i>Mediolanum</i> y fallecidos
                 a la edad de 28 años, después de nueve años de servicio militar en los <i>equites speculatores</i>, el departamento montado del cuerpo
                 de los pretorianos. Es presumible que Sulpicius Peregrino desempeñó parte de su servicio en la residencia imperial de
                 Anzio, donde habría muerto.<br><br>
                 A la derecha un sarcófago con estrigilaciones enmarcadas por pilastras de orden corintio y en el centro una tabula ansata sin
                 inscripción o representación. El párpado, de tipo consistente, con tabula ansata anepigrafiada, probablemente no sea pertinente.
                 Es un producto típico de los talleres urbanos de mármol del III-IV d.C.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/3-Sala_III.jpg" alt="Sala III">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEs from '@/components/TopbarSaleEs.vue';

    export default {
        name: 'Sala3Es',
        components: {
            TopbarSaleEs
        }
    }
</script>