<template>
    <div>
        <TopbarSaleEn titolo="HALL II"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/2-sala_II.jpg" alt="Hall II">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">HALL II</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/hall_two.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Listen to the audio track</i></p>
            <p class="desContenutoSala">On the left, is showcase 3, dedicated to archaeological evidence from the middle and 
                late Republican age (4th-2nd century BC). These are, for the most part, statuettes and anatomical parts of a votive character. 
                Of particular note is a fragment of overpainted black glazed pottery, with painted letters relating to the 
                end of a theonym, possibly Fortuna, the main deity worshipped at Anzio, in the form Fortune, i.e. 'alla Fortuna' 
                or 'of Fortune'.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_3.jpg" alt="Vetrina sala II">
            </div>
            
            <p class="desContenutoSala">
                Of particular note is the unique <i>Guttus</i>, an ancient black-glazed pottery bottle in the form of an elephant, 
                mounted by cornac, dating back to the 3rd century BC. 
                The vase was found in Anzio in 1954 during the construction of a building in Via Roma. It is an <i>unicum</i> of its kind in 
                the Italic area. The attention to detail and the movement of the animal ensure direct knowledge of the subject by the 
                ceramist, possibly Apulian. In the space between the front and hind legs, an array of armed men with spears and shields 
                is inserted in relief, advancing at the elephant's sides, qualifying its military function.<br><br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_guttus.jpg" alt="Sala II">
            </div>
            

            <h3 class="titoloContenutoSala">THE MAIDEN OF ANZIO</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/maiden.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">
                The statue was found on 20 December 1878 in a niche in the imperial villa after a strong sea storm. Initially placed in the entrance hall of Villa Sarsina, the residence of the Aldobrandini princes, because it had been found on their property, it was later purchased in 1909 by the Italian State for the considerable sum, for the time, of 450,000 lire.
                The statue depicts a female figure, little more than an adolescent, wearing a chiton and a rather heavy cloak, worn knotted at the hips; she wears simple sandals and has her hair up in an unusual hairstyle.
                His hands hold a tray with some objects on it: a twig and laurel berries, a sacrificial bandage, two lion paws attributable to a container.
                Some technical details and the reworking of the base plinth have led to the hypothesis that it was part of a statuary group, perhaps referable to the Dionysian sphere. It would be a Hellenistic original datable to the 3rd century BC.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/fanciulla.jpg" alt="Statua Fanciulla d'Anzio">
            </div>

            <h3 class="titoloContenutoSala">THE ROMAN AGE</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/roman_age.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">
                On the shelf to the right is part of a relief, datable to the beginning of the 3rd s eculus, depicting a sacrifice scene 
                with sacred architecture with Egyptian-type capitals in the background. 
                On the wall, to the right, a fragment of a sarcophagus from the Imperial age with a representation of a horse pulling 
                a chariot. On the left, two sepulchral inscriptions: above: <i>'Clodia Ge(mella), freedwoman of Gaius, made for herself and 
                her sister Clodia Gemella, freedwoman of Gaius'</i> (late 1st-early 2nd century A.D.); below: To the Gods Hands of Successus, 
                imperial slave (late 1st century A.D.).
            </p>

            <div class="image-container">
                <img src="@/assets/sale/2-sala_II.jpg" alt="Sala II">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'Hall2En',
        components: {
            TopbarSaleEn
        }
    }
</script>