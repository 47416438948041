<template>
    <div>
        <TopbarSaleIt titolo="SALA IX"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/9-Sala_IX.jpg" alt="Sala IX">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA IX <br> SALA DELLE MONETE</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_nove.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">
            Nel piccolo medagliere è esposta una piccola collezione di monete romane di età repubblicana e imperiale che possono 
            essere osservate su entrambi i lati. 
            </p>

            <div class="image-container">
                <img src="@/assets/sale/9-Sala_IX.jpg" alt="Sala IX">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala9It',
        components: {
            TopbarSaleIt
        }
    }
</script>