<template>
    <div>
        <TopbarSaleEn titolo="HALL VI"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/6-Sala_VI.jpg" alt="Hall VI">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">HALL VI "ROBERTO CONFORTI"</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/hall_six.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">At the entrance on two sides: capitals of various types and a headless statue of Artemis dated to the 2nd century. A.D.
                 Along the walls you can admire 5 panels of painted plaster, datable to the II-III century. AD, found as a result of
                 a landslide in the area of the imperial villa of Anzio in 1970. The representation is of the garden type, with the representation
                 of a landscape occupied by luxuriant flora inhabited by different species of birds, observed from a bordered balcony
                 by a light trellis parapet. It is possible that the complex they were in was a resting place or a nymphaeum.
                 The plasters were removed to ensure their conservation and have only partially been restored and preserved in Anzio. For 
                this reason the position of the individual elements does not correspond to the original one, articulated in a high wall on which it
                 they alternately opened rectilinear and curvilinear niches.<br><br>
                 On the right wall are the reproductions of the so-called <i>Arae Ventorum</i> (altars of the winds), found in Anzio and
                 preserved in the Capitoline Museums. These are three column-shaped votive altars, with mention of the winds and addition of the
                 reproduction of the rostrum of the warships, the metal spur with which the tip of the warships was armed to gut
                 the enemy ships, Roman prey in the battle of Astura in 338 BC.<br><br>
                 At the center of the room is one of the most important discoveries made in the sea of Anzio in recent years. It's about a
                 headless male statue, in grayish-black marble, with baldric, the transversal band on the chest, ending in feral paws.
                 This element could suggest that it is the representation of a character of the Dionysian circle or of
                 Dionysus himself. First half of the 2nd century AD.<br><br>
                 In room VI, on the ground bases, some marble artifacts of various types and origins are exhibited. From the sea of Anzio
                 the four composite capitals with water leaves from the end of the 1st – beginning of the 2nd century AD and the two elements come from
                 architectural elements with series of dentils and ovules (end of the 1st – mid-2nd century AD).<br>
                 The base of a draped statue, probably female, from the 2nd century BC has no certain indication of provenance and
                 the base of a male statue with shod feet and two props, one of which consists of a cuirass, datable to the 1st century B.C.<br>
                 To these finds must be added the fragment of a mosaic floor recovered at the Muto Arch in the area of the imperial villa.
                 It can probably be dated to the Severan age (first decades of the 3rd century AD).<br><br>
                 Fragments of various types pertaining to statues or marble reliefs of the imperial age are displayed on the table tops.<br>
                 On the left table: relief part with cornucopia, male bust with part of the cloak and male bust with cuirass
                 to aegis.<br>
                 On the right table: angular acroterion (2nd century AD), statue support with plant decoration; male figurine with tapeworm.<br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/6-Sala_VI.jpg" alt="Sala VI">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'Hall6En',
        components: {
            TopbarSaleEn
        }
    }
</script>