<template>
    <div>
        <TopbarSaleEs titolo="SALA II"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/2-sala_II.jpg" alt="Sala II">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA II</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/sala_dos.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">A la izquierda, la vitrina 3, dedicada a los testimonios arqueológicos de la Edad Media y
                 republicano tardío (siglos IV-II a.C.). Estos son, en su mayor parte, estatuillas y partes anatómicas de carácter
                 votivo. Destaca el fragmento de cerámica vidriada negra sobrepintada, con letras pintadas relativas a la pieza
                 terminal de un teónimo, quizás Fortuna, la principal divinidad venerada en Anzio, en la forma Fortuna, o "a Fortuna"
                 o "de la fortuna".
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_3.jpg" alt="Vetrina sala II">
            </div>
            
            <p class="desContenutoSala">
                Destaca el singular <i>Guttus</i>, un antiguo biberón, realizado en cerámica pintada de negro con forma de elefante,
                 montado por cornac, que data del siglo III a.C.
                 El jarrón fue encontrado en Anzio en 1954 durante la construcción de un edificio en via Roma. Es un <i>unicum</i>
                 de su tipo en Italia. La atención al detalle y al movimiento del animal asegura un conocimiento directo del
                 tema por el alfarero, quizás de Apulia. En el espacio entre las patas delanteras y traseras, se inserta un
                 relevo un grupo de hombres armados con lanzas y escudos avanzando a los costados del elefante, calificando su función militar.
                <br><br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_guttus.jpg" alt="Sala II">
            </div>
            

            <h3 class="titoloContenutoSala">LA CHICA DE ANZIO</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/chica.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">
                La estatua fue encontrada el 20 de diciembre de 1878, a causa de un fuerte temporal marino, en un nicho de la villa imperial. Situada inicialmente en el vestíbulo de entrada de Villa Sarsina, residencia de los príncipes Aldobrandini, ya que había sido encontrada en su propiedad, fue adquirida posteriormente en 1909 por el Estado italiano por la considerable suma, para la época, de 450.000 liras.
                 La estatua representa a un personaje femenino, poco más que una adolescente, ataviada con un quitón y un manto bastante pesado, anudado a la cadera; ella usa sandalias simples y usa su cabello en un peinado inusual.
                 Las manos sostienen una bandeja con algunos objetos: una ramita y bayas de laurel, una venda de sacrificio, dos patas de león atribuibles a un contenedor.
                 Algunos detalles técnicos y la reelaboración del zócalo de la base han llevado a la hipótesis de que formaba parte de un grupo de estatuas, quizás atribuibles a la esfera dionisiaca. Sería un original helenístico fechable en el siglo III a.C.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/fanciulla.jpg" alt="Statua Fanciulla d'Anzio">
            </div>

            <h3 class="titoloContenutoSala">LA ERA ROMANA</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/era_romana.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">
                En la repisa de la derecha se encuentra parte de un relieve, datable a principios del siglo III, donde una escena de
                 sacrificio con arquitecturas de fondo de ambiente sacro con capiteles de tipo egipcio.
                 En la pared, a la derecha, fragmento de sarcófago de época imperial con representación de un caballo tirando
                 un carro. A la izquierda, dos inscripciones sepulcrales: arriba <i>"Clodia Ge(mella), liberta de Gayo, hecha para ella y para ella
                 sor Clodia Gemella, liberta de Gayo”</i> (finales del siglo I-principios del siglo II d. C.); abajo: A las Manos de Dios de Successus, esclavo
                 imperial (finales del siglo I d.C.).
            </p>

            <div class="image-container">
                <img src="@/assets/sale/2-sala_II.jpg" alt="Sala II">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEs from '@/components/TopbarSaleEs.vue';

    export default {
        name: 'Sala2Es',
        components: {
            TopbarSaleEs
        }
    }
</script>