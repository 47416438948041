<template>
    <div>
        <TopbarInternaFr /> 

        <div class="infoSale">
            <img id="ingresso" src="@/assets/ingresso.jpg" alt="Entrée du musée">
            <router-link to="/fr/musee"><p class="btnLingua">Découvrir le musée</p></router-link>
            <img id="assonometria" src="@/assets/assonometria.jpg" alt="Carte en 3D">
            <p id="titoloSale">Les Salles</p>
        </div>
        
        <div class="sale">
            <div class="sala">
                <router-link to="/fr/salle-1">
                    <img class="salaImg" src="@/assets/sale/1-sala_I.jpg" alt="Salle 1">
                    <p class="salaDes">I</p>
                </router-link>
            </div>     
            
            <div class="sala">
                <router-link to="/fr/salle-2">
                    <img class="salaImg" src="@/assets/sale/2-sala_II.jpg" alt="Salle 2">
                    <p class="salaDes">II</p>
                </router-link>
            </div>

            <div class="sala">
                <router-link to="/fr/salle-3">
                    <img class="salaImg" src="@/assets/sale/3-Sala_III.jpg" alt="Salle 3">
                    <p class="salaDes">III</p>
                </router-link>
            </div>
        </div>

        <div class="sale">
            <div class="sala">
                <router-link to="/fr/salle-4">
                    <img class="salaImg" src="@/assets/sale/4-Sala_IV.jpg" alt="Salle 4">
                    <p class="salaDes">IV</p>
                </router-link>
            </div>     
            
            <div class="sala">
                <router-link to="/fr/salle-5">
                    <img class="salaImg" src="@/assets/sale/5-Sala_V.jpg" alt="Salle 5">
                    <p class="salaDes">V</p>
                </router-link>
            </div>

            <div class="sala">
                <router-link to="/fr/salle-6">
                    <img class="salaImg" src="@/assets/sale/6-Sala_VI.jpg" alt="Salle 6">
                    <p class="salaDes">VI</p>
                </router-link>
            </div>
        </div>

        <div class="sale">
            <div class="sala">
                <router-link to="/fr/salle-7">
                    <img class="salaImg" src="@/assets/sale/7-Sala_VII.jpg" alt="Salle 7">
                    <p class="salaDes">VII</p>
                </router-link>
            </div>     
            
            <div class="sala">
                <router-link to="/fr/salle-8">
                    <img class="salaImg" src="@/assets/sale/8-Sala_VIII.jpg" alt="Salle 8">
                    <p class="salaDes">VIII</p>
                </router-link>
            </div>

            <div class="sala">
                <router-link to="/fr/salle-9">
                    <img class="salaImg" src="@/assets/sale/9-Sala_IX.jpg" alt="Salle 9">
                    <p class="salaDes">IX</p>
                </router-link>
            </div>
        </div>

        
    </div>
</template>

<script>
    import TopbarInternaFr from '@/components/TopbarInternaFr.vue';

    export default {
        name: 'FrHome',
        components: {
            TopbarInternaFr
        }
    }
</script>