<template>
    <div>
        <TopbarSaleEn titolo="HALL IV"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/4-Sala_IV.jpg" alt="Hall IV">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">HALL IV</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/hall_four.mp3" type="audio/mpeg">
            </audio>            
            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">
                The room displays objects related to various aspects of daily life in Anzio in the Roman Imperial age.
                 <br><br>
                 In the showcase on the left are some fragments of brick architectural material from the 1st century A.D.,
                 intended to cover roofs and architraves of buildings or monumental tombs: a slab with a Gorgon's head; a fragment
                 of architectural cladding slab with part of a male figure and shield, with still traces of the original colour;
                 a palmette antefix. Some fragments of painted plaster can be referred to the same area.<br>
                 On the other hand, the large terracotta mortar with factory stamp can be referred to cooking activities
                 <i>Augustalis Saturnini</i> from the 1st century AD.

            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_4.jpg" alt="Vetrina sala IV">
            </div>      
            
            <p class="desContenutoSala">
                In the showcase on the right are displayed: on the top shelf, a small lead mirror from the 1st century AD and some vases in
                 imperial age bronze. Two jugs and a situla with Silenus mask appliques.<br>
                 On the middle shelf there are several fragments of window glass, some bronze coins, including two sestertii
                 of the imperial age, and some particular finds:<br><br>
                 - two <i>tabellae defixionum</i>, i.e. lead tables on which insulting sentences and real ones were engraved
                 curses aimed at a hated or enemy person. These are typical objects of the competitive field linked to games
                 circus performers who often wish the worst evils to the charioteers or even the horses of the opposing teams;<br>
                 - another particular object is the <i>signaculum</i> or bronze stamp. It was used to leave an impression before cooking,
                 real hallmark on some food products, such as bread;<br>
                 - finally, part of a dish of a production defined as Southern Gallic, dated to the 1st century A.D. of a rather kind
                 rare that has the surfaces covered with a varnish that gives a particular chromatic effect called marbled.<br><br>
                 On the lowest shelf is part of a pipe, a lead fistula, with the inscription in relief of the name of the <i>plumbarius</i> which
                 in the imperial age he created it: <i>Alexander fecit</i>.      
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_5.jpg" alt="Vetrina sala IV">
            </div> 
        </div>       
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'Hall4En',
        components: {
            TopbarSaleEn
        }
    }
</script>