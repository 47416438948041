<template>
    <div>
        <TopbarSaleFr titolo="SALLE V"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/5-Sala_V.jpg" alt="Salle V">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALLE V<br>LE NYMPHÉE D'HERCULES</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_5.1.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">L'un des objets les plus importants découverts à Anzio est la niche avec une représentation en mosaïque 
            d'Hercule. La niche, datable de la période julio-claudienne, milieu du premier siècle avant J.-C., a été découverte en 1930 
            lors de travaux de démolition de structures de l'époque romaine impériale effectués sur la Piazza del Fontanile, au pied de 
            la colline sur laquelle est construite Villa Corsini, siège actuel de la municipalité d'Anzio.<br>
            Dans la représentation principale apparaît Hercule, à demi couché et adossé à un rocher, la tête ceinte d'une couronne, probablement 
            faite de lierre. Le héros, en état d'ébriété, s'appuie sur la massue de la main droite et tient un <i>skyphos</i>, une coupe à boire, dans 
            la main gauche. Sur les côtés, à gauche, la silhouette athlétique d'un jeune homme, nu et debout ; à droite, un cochon devant une 
            grotte. Le panneau est entouré d'un décor composé de cadres de mosaïque récurrents, comprenant une théorie de palmettes, des 
            imitations de rochers (imitation réalisée avec des incrustations de calcaire) et des récurrences de coquillages, selon un goût 
            typique de l'époque julio-claudienne tardive et surtout néronienne.<br>
            Les circonstances de la découverte rendent difficile l'interprétation fonctionnelle du complexe, que l'on pense être un nymphée 
            (fontaine monumentale) ou un sacellum. Il est probable qu'il s'agissait d'un lieu d'oisiveté où l'on pouvait s'abriter de la chaleur 
            estivale, faisant partie d'une villa ou d'un jardin.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/10-ercole.jpg" alt="Le Nymphée d'Hercule">
            </div>          
            
            <h3 class="titoloContenutoSala">ANZIO ET LA MER</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_5.2.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">
                A l'entrée de la salle V, à gauche, se trouve un grand dolio pour le transport des denrées alimentaires. Ce type de récipient était 
                placé dans le fond de cale des bateaux directement lors de la construction des navires. Les plus importantes fabriques de ce type de 
                dolio se trouvaient dans la colonie romaine de Minturnae (aujourd'hui Minturno, à l'embouchure du fleuve Liri-Garigliano).<br><br>
                À droite, on peut voir quelques-uns des types d'amphores de transport les plus courants et les plus répandus, de production italique, 
                espagnole et africaine. Elles proviennent de découvertes sous-marines et terrestres et témoignent du réseau de relations commerciales 
                entre la fin de l'époque républicaine et l'époque impériale.<br><br>
                Sur l'étagère à terre, on trouve également un rondin d'ancre en plomb de l'époque romaine ; sur le panneau, une partie d'un relief en 
                marbre représentant un dauphin. Une autre découverte sous-marine importante, faite à quelques kilomètres au nord d'Anzio, est le torse 
                d'une statue masculine en marbre blanc (fin du Ier siècle après J.-C. - première moitié du IIe siècle après J.-C.).<br><br>
                À côté se trouve une partie d'un support en forme d'hermès avec une tête barbue. Adossée à cet élément se trouvait une statue, 
                peut-être Dionysos en état d'ébriété, dont il reste une partie du bras et du manteau. IIe siècle après J.-C.<br><br>
                Près du pilier central de la pièce se trouve un modèle reconstitué du port néronien avec un panneau illustrant la technique de 
                construction des jetées du port.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/11-Anfore.jpg" alt="Anfore">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'Salle5Fr',
        components: {
            TopbarSaleFr
        }
    }
</script>