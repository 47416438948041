<template>
    <div>
        <TopbarSaleIt titolo="SALA VI"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/6-Sala_VI.jpg" alt="Sala VI">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA VI "ROBERTO CONFORTI"</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_sei.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">All’ingresso su due lati: capitelli di varia tipologia e una statua acefala di Artemide datata al II sec. d.C.
                Lungo le pareti si possono ammirare 5 pannelli di intonaci dipinti, databili al II-III sec. d.C., rinvenuti a seguito di 
                una frana nell’area della villa imperiale di Anzio nel 1970. La rappresentazione è del tipo a giardino, con la raffigurazione 
                di un paesaggio occupato da una flora lussureggiante abitata da diverse specie di uccelli, osservato da un balcone delimitato 
                da un leggero parapetto a graticcio. È possibile che il complesso nel quale si trovavano fosse un luogo di riposo o un ninfeo. 
                Gli intonaci furono rimossi per garantirne la conservazione e solo in parte sono stati restaurati e conservati ad Anzio. Per 
                questo motivo la posizione dei singoli elementi non corrisponde a quella originaria, articolata in una alta parete su cui si 
                aprivano alternativamente nicchie rettilinee e curvilinee.<br><br>
                Sulla parete di destra sono le riproduzioni delle cosiddette <i>Arae Ventorum</i> (altari dei venti), rinvenute ad Anzio e 
                conservate nei Musei capitolini. Si tratta di tre altari votivi a forma di colonna, con menzione dei venti e aggiunta della 
                riproduzione del rostro delle navi anziati, lo sperone di metallo con cui si armava la punta delle navi da guerra per sventrare 
                le navi nemiche, preda romana nella battaglia di Astura del 338 a.C.<br><br>
                Al centro della sala è uno dei ritrovamenti più importanti effettuati nel mare di Anzio in anni recenti. Si tratta di una 
                statua maschile acefala, di marmo nero-grigiastro, con balteo, la fascia trasversale sul petto, terminante con zampe ferine. 
                Questo elemento potrebbe far ipotizzare che si tratti della raffigurazione di un personaggio della cerchia dionisiaca o di 
                Dioniso stesso. Prima metà del II secolo d.C.<br><br>
                Nella sala VI, sulle basi a terra, sono esposti alcuni manufatti in marmo di varia tipologia e provenienza. Dal mare di Anzio 
                provengono i quattro capitelli compositi a foglie d’acqua della fine del I – inizi del II secolo d.C. e i due elementi 
                architettonici con serie di dentelli e ovoli (fine del I – metà del II secolo d.C.).<br>
                Privi di indicazione certa della provenienza sono la base di statua panneggiata, probabilmente femminile, del II sec. d.C. e 
                la base di statua maschile con piedi calzati e due puntelli, di cui uno costituito da una corazza, databile al I sec. a.C.<br>
                A questi reperti va aggiunto il lacerto di pavimento a mosaico recuperato presso l’Arco Muto nell’area della villa imperiale. 
                É databile probabilmente all’età severiana (primi decenni del III sec. d.C.)<br><br>
                Sui ripiani a tavolo sono esposti frammenti di varia tipologia pertinenti a statue o rilievi marmorei di età imperiale.<br>     
                Sul tavolo di sinistra: parte di rilievo con cornucopia, busto maschile con parte del mantello e busto maschile con corazza 
                ad egida.<br>
                Sul tavolo di destra: acroterio angolare (II sec. d.C.), sostegno di statua con decorazione vegetale; statuetta maschile con tenia.<br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/6-Sala_VI.jpg" alt="Sala VI">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala6It',
        components: {
            TopbarSaleIt
        }
    }
</script>