<template>
    <div>
        <TopbarSaleFr titolo="LE MUSÉE"/> 

        

        <div class="contenutoSala">          
            <img id="ingresso" src="@/assets/pianta_museale.png" alt="Pianta museale">
            <h3 class="titoloContenutoSala">VILLA ADELE anciennement VILLA PIA</h3>
            
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Museo.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">En 1615, le cardinal Bartolomeo Cesi di Acquasparta, trésorier général de la Chambre apostolique, 
                fait construire à Anzio une villa résidentielle sur le site connu sous le nom de "Torre d'Imperio" (Soffredini 1879, 115). 
                En 1648, la villa est vendue par son neveu, Giovanni Federico Cesi, au prince Camillo Pamphilj, neveu du pape Innocent X 
                (1644-1655), qui agrandit et embellit les bâtiments. En 1760, la propriété de la villa passe à la branche Doria Pamphilj, 
                à laquelle succèdent d'abord la famille Borghese en 1834, puis la famille Aldobrandini en 1851. Après le démembrement 
                partiel du domaine dans la première décennie du XXe siècle, le casino a été transformé en hôtel, l'Excelsior Palace Hotel. 
                C'est de cette époque que date le tracé définitif de la façade principale, avec un portique à trois arches donnant accès à 
                la Sala delle Conchiglie, de forme octogonale. En 1964, l'ensemble de la zone est devenu la propriété de la municipalité 
                d'Anzio. Actuellement, le parc est ouvert au public et le casino principal abrite le musée archéologique civique, depuis 
                2002, le musée du débarquement, depuis 1994, et la bibliothèque municipale.
            </p>

            <div class="image-container">
                <img src="@/assets/ingresso.jpg" alt="Ingresso museo">
                <img src="@/assets/MUS_00001.jpg" alt="Museo">
                <img src="@/assets/MUS_00002.jpg" alt="Museo">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'FrMusee',
        components: {
            TopbarSaleFr
        }
    }
</script>