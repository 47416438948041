<template>
    <div>
        <TopbarSaleIt titolo="SALA VII"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/7-Sala_VII.jpg" alt="Sala VII">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA VII</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_sette.mp3" type="audio/mpeg">
            </audio>   
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">Nella sala è esposto un dolio da trasporto, rinvenuto in mare, dello stesso tipo di quello esposto 
            nella sala V.<br>
            A terra, tre iscrizioni sepolcrali. Due sono frammentarie, la prima con riporta tracce di rilavorazione per un utilizzo con 
            diversa funzione del blocco di marmo, la seconda è riportata su parte di un cippo. La terza ha invece restituito il testo completo 
            ed è riferibili a soggetti di condizione libertina.<br><br>
            L’elemento di maggiore rilievo della sala è la copia del cosiddetto <i>Gladiatore Borghese</i>. La statua, rinvenuta intorno al 1609 
            ad Anzio, fu acquistata da Napoleone nel 1807 e destinata ad arricchire le collezioni del Museo del Louvre dove è tuttora 
            conservata. &Egrave; opera dello scultore di Efeso Agasias e viene concordemente datata intorno al 100 a.C.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/7-Sala_VII.jpg" alt="Sala VII">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala7It',
        components: {
            TopbarSaleIt
        }
    }
</script>