<template>
    <div>
        <TopbarSaleEs titolo="EL MUSEO"/> 

        

        <div class="contenutoSala">          
            <img id="ingresso" src="@/assets/pianta_museale.png" alt="Pianta museale">
            <h3 class="titoloContenutoSala">VILLA ADELE antes VILLA PIA</h3>
            
            <audio controls class="audio">
                <source src="@/assets/audio/es/museo.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">En 1615, el cardenal Bartolomeo Cesi di Acquasparta, tesorero general de la Cámara Apostólica,
                 hizo construir una villa residencial en Anzio en el lugar conocido como la "Torre del Imperio" (Soffredini 1879, 115). En 1648,
                 la villa fue vendida por su sobrino, Giovanni Federico Cesi, al Príncipe Camillo Pamphilj, sobrino del Papa Inocencio X (1644-1655),
                 quien amplió y embelleció los edificios. En 1760, la propiedad de la villa pasó a la sucursal de Doria Pamphilj, que se hará cargo primero
                 la familia Borghese, en 1834, y luego la familia Aldobrandini en 1851. Después del desmembramiento parcial de la finca, que tuvo lugar en
                 primera década del siglo XX, el casino se transformó en un hotel, el "Excelsior Palace Hotel". Se remonta a este período la
                 disposición definitiva de la fachada principal, con un pórtico de tres arcos que permite el acceso a la denominada Sala
                 delle Conchiglie, de planta octogonal. A partir de 1964, toda el área pasó a ser propiedad del Municipio de Anzio.
                 Actualmente, el parque está destinado a uso público y el casino principal alberga el Museo Arqueológico Cívico, desde 2002,
                 el Museo del Desembarco, desde 1994, y la biblioteca municipal.
            </p>

            <div class="image-container">
                <img src="@/assets/ingresso.jpg" alt="Ingresso museo">
                <img src="@/assets/MUS_00001.jpg" alt="Museo">
                <img src="@/assets/MUS_00002.jpg" alt="Museo">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEs from '@/components/TopbarSaleEs.vue';

    export default {
        name: 'EsMuseo',
        components: {
            TopbarSaleEs
        }
    }
</script>