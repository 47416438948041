<template>
    <div>
        <TopbarSaleIt titolo="SALA VIII"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/8-Sala_VIII.jpg" alt="Sala VIII">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA VIII</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_otto.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">Nella sala sono esposti documenti, disegni e fotografie che illustrano la ricchezza della 
            documentazione esistente sulle antichità di Anzio. La vera e propria “Fortuna di Anzio”.
            <br><br>
            Sul pannello di sinistra sono documenti e approfondimenti relativi a importanti ritrovamenti o siti archeologici di Anzio e dei 
            dintorni. In particolare, alcune vicende legate all’acquisto della Fanciulla d’Anzio da parte dello stato italiano con la prima 
            pagina di un quotidiano dell’epoca; documenti d’archivio relativi ai progetti di costruzione di mede e fanali sui ruderi del porto 
            neroniano; le fotografie di torre Astura trasformata nel set cinematografico del film Cleopatra, con Richard Burton e Liz Taylor.
            <br><br>
            Sulla vicina parete è la fotografia realizzata dal Genio Civile nel 1909 da allegare alla valutazione della fanciulla d’Anzio 
            in cui è riportato l’esatto punto del ritrovamento.
            <br><br>
            Sul pannello di destra sono esposti alcuni documenti, disegni e fotografie relativi alla storia dei ritrovamenti effettuati 
            nell’area della villa imperiale, dai disegni pubblicati dal Volpi e altri eruditi nel corso del Settecento, ai rilievi 
            ottocenteschi di Rodolfo Lanciani, ai ritrovamenti effettuati nei primi decenni del Novecento, come il calendario pre-cesariano 
            e la statua di amazzone a cavallo che travolge un barbaro attualmente conservata a Roma nel Museo Nazionale Romano.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/8-Sala_VIII.jpg" alt="Sala VIII">
            </div>
            
            
            <h3 class="titoloContenutoSala">REPERTI</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_otto_reperti.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">Nella sala sono esposti anche alcuni reperti. <br>
            1) Parte di un braccio in marmo bianco, databile ad età imperiale e relativo ad una statua di dimensioni maggiori del vero; <br>
            2) pretoriana imperiale; <br>
            3-4) sui supporti celesti: iscrizioni sepolcrali frammentariei; <br>
            5-6) per terra è un’altra iscrizione sepolcrale, mutila, e parte di una significativa iscrizione che riporta la realizzazione 
            di un’opera pubblica, dove viene menzionata una via tecta, cioè coperta e il foro. Purtroppo, le circostanze e il luogo del 
            ritrovamento si basano su notizie non certe. Inoltre, il pezzo è stato sicuramente resecato e riutilizzato come soglia già in 
            antico spostandolo dal luogo in cui era originariamente posto.
            </p>
        </div>      
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala8It',
        components: {
            TopbarSaleIt
        }
    }
</script>