<template>
    <div>
        <TopbarSaleFr titolo="SALLE VI"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/6-Sala_VI.jpg" alt="Salle VI">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALLE VI "ROBERTO CONFORTI"</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_6.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">À l'entrée, sur deux côtés: des chapiteaux de différents types et une statue d'Artémis sans tête, 
                datée du IIe siècle après Jésus-Christ. Le long des murs, on peut admirer cinq panneaux en plâtre peint, datés des IIe et IIIe 
                siècles après J.-C., qui ont été découverts à la suite d'un glissement de terrain dans la zone de la villa impériale d'Anzio 
                en 1970. La représentation est de type jardin, décrivant un paysage occupé par une flore luxuriante et peuplé de différentes 
                espèces d'oiseaux, observé depuis un balcon bordé d'un léger parapet en treillis. Il est possible que l'ensemble dans lequel 
                ils se trouvaient ait été un lieu de repos ou un nymphée. Les plâtres ont été enlevés pour assurer leur conservation et seules 
                des parties ont été restaurées et conservées à Anzio. C'est pourquoi la position des différents éléments ne correspond pas à 
                celle d'origine, qui s'articulait autour d'un haut mur sur lequel s'ouvraient alternativement des niches rectilignes et 
                curvilignes.<br><br>
                Sur le mur de droite se trouvent des reproductions de ce que l'on appelle les <i>Arae Ventorum</i> (autels des vents), découverts à Anzio 
                et conservés dans les Musées du Capitole. Il s'agit de trois autels votifs en forme de colonnes, avec la mention des vents et 
                l'ajout de la reproduction du rostre des navires d'Anzio, l'éperon métallique avec lequel les pointes des navires de guerre étaient 
                armées pour éventrer les navires ennemis, proie des Romains lors de la bataille d'Astura en 338 av.<br><br>
                A pièce maîtresse de la salle est l'une des plus importantes découvertes faites dans la mer d'Anzio ces dernières années. Il s'agit 
                d'une statue masculine sans tête, en marbre gris-noir, dont le balteus, la bande transversale sur la poitrine, se termine par des 
                pattes de fauve. Cet élément pourrait suggérer qu'il s'agit d'une représentation d'un personnage du cercle dionysiaque ou de Dionysos 
                lui-même. Première moitié du IIe siècle après J.-C.<br><br>
                Dans la salle VI, plusieurs objets en marbre de nature et d'origine diverses sont exposés sur les socles du sol. De la mer d'Anzio proviennent 
                les quatre chapiteaux composites à feuilles d'eau de la fin du Ier siècle - début du IIe siècle après J.-C. et les deux éléments architecturaux 
                avec une série de denticules et d'oves (fin du Ier siècle - milieu du IIe siècle après J.-C.).<br>
                La base d'une statue drapée, probablement féminine, du IIe siècle après J.-C. et la base d'une statue masculine avec des pieds chaussés et deux 
                accessoires, dont l'un consiste en une cuirasse, datables du Ier siècle avant J.-C., manquent d'indications certaines de provenance.<br>
                À ces découvertes, il faut ajouter le fragment de sol en mosaïque retrouvé près de l'Arco Muto, dans la zone de la villa impériale. Il peut 
                probablement être daté de la période sévérienne (premières décennies du IIIe siècle après J.-C.).<br><br>
                Sur les tables sont exposés des fragments de différents types de statues ou de reliefs en marbre de l'époque impériale.<br>     
                Sur la table de gauche: partie d'un relief avec une corne d'abondance, un buste d'homme avec une partie d'un manteau et un buste d'homme avec 
                une cuirasse à égide.<br>
                Sur la table de droite: acrotère angulaire (IIe siècle après J.-C.), support de statue à décor végétal ; statuette masculine avec tenia.<br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/6-Sala_VI.jpg" alt="Salle VI">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'Salle6Fr',
        components: {
            TopbarSaleFr
        }
    }
</script>