<template>
    <div>
        <TopbarSaleEs titolo="SALA I"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/1-sala_I.jpg" alt="Sala I">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA I <br>LOS ORÍGENES ANTIGUOS DE LA CIUDAD - ANTIUM LATINA (MATERIAL DEL SIGLO X-IX A.C.)</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/sala_uno.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">La primera evidencia de ocupación estable por parte de los latinos del área de Anzio se remonta al siglo X a. C.;
                 entre finales del siglo VI y principios del V a.C. el sur del Lacio está invadido por la invasión de los volscos,
                 población de linaje osco-umbro: Anzio, inmediatamente conquistada, se convirtió en un formidable adversario de Roma en el siglo V.
                 El poder volcánico declinó definitivamente en la segunda mitad del siglo IV a.C.<br>
                 A partir de este período se afirmó el dominio romano: Anzio, como el resto de Lazio y el norte de Campania,
                 fue conquistada por Roma en el 338 a. Habiendo perdido su autonomía, la ciudad acoge la deducción de una colonia compuesta por 300
                 ciudadanos provistos de los plenos derechos de la ciudadanía romana. Pecco, un pequeño grupo de colonos, tuvo que defender el puerto de
                 ciudad y la carretera que conducía a los Colli Albani.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/1-sala_I.jpg" alt="Sala I">
                <img src="@/assets/sale/vetrina_1.jpg" alt="Vetrina sala I">
                <img src="@/assets/sale/vetrina_2.jpg" alt="Vetrina sala II">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEs from '@/components/TopbarSaleEs.vue';

    export default {
        name: 'Sala1Es',
        components: {
            TopbarSaleEs
        }
    }
</script>