<template>
    <div>
        <TopbarSaleFr titolo="SALLE VII"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/7-Sala_VII.jpg" alt="Salle VII">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALLE VII</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_7.mp3" type="audio/mpeg">
            </audio>   
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">Dans la salle est exposé un dolio de transport, trouvé en mer, du même type que celui exposé dans la salle V.<br>
            Au sol, trois inscriptions sépulcrales. Deux sont fragmentaires, la première avec des traces de remaniement dues à une utilisation différente 
            du bloc de marbre, la deuxième est sur une partie d'un cippus. La troisième a restitué le texte complet et se réfère à des sujets de statut libertin.<br><br>
            L'élément le plus important de la salle est la copie du <i>Gladiateur Borghèse</i>. La statue, trouvée vers 1609 à Anzio, fut achetée par Napoléon en 1807 
            et destinée à enrichir les collections du musée du Louvre où elle se trouve encore aujourd'hui. Elle est l'œuvre du sculpteur Agasias d'Éphèse et on 
            s'accorde à la dater d'environ 100 ans avant Jésus-Christ.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/7-Sala_VII.jpg" alt="Salle VII">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'Salle7Fr',
        components: {
            TopbarSaleFr
        }
    }
</script>