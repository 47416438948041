<template>
    <div>
        <TopbarSaleIt titolo="SALA III"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/3-Sala_III.jpg" alt="Sala III">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA III</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_tre.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Ascolta la traccia audio</i></p>
            <p class="desContenutoSala">La sala è dedicata a reperti marmorei in gran parte conservati un tempo nei giardini 
                dell’attuale villa comunale, già Doria Pamphilj.<br><br>
                A sinistra, sulla parete, sono esposti diversi frammenti marmorei riferibili a tre statuette databili al 
                II sec. d.C.: due, stanti, identificabili, anche se frammentarie, con Artemide cacciatrice e una, seduta, 
                forse identificabile con la Fortuna; sono esposti inoltre diversi frammenti di urne cinerarie e sarcofagi 
                databili tra I e III secolo d.C. Poggiato a terra un cippo funerario anepigrafe.<br><br>
                Sul ripiano è l’urna cineraria di Publio Sulpicio Peregrino, datata ad età flavia (seconda metà del I secolo d.C.). 
                Si tratta di un ritrovamento effettuato ad Anzio in località La Sacida negli anni Quaranta del Novecento, costituito 
                da una stele con iscrizione, attualmente conservata a Roma nel Museo Nazionale Romano, e dall’urna a cassetta in esposizione: 
                rettangolare, in marmo, mancante del coperchio, andato perduto, che doveva essere agganciato mediante grappe di ferro. 
                Il manufatto, realizzato con eleganza e maestria, doveva raccogliere le ceneri del defunto, nato a <i>Mediolanum</i> e morto 
                all’età di 28 anni, dopo averne prestati nove di servizio militare negli <i>equites speculatores</i>, reparto a cavallo del corpo 
                dei Pretoriani. É presumibile che Sulpicio Peregrino abbia svolto parte del suo servizio presso la residenza imperiale di 
                Anzio, dove sarebbe deceduto.<br><br>
                Sulla destra è un sarcofago con strigilature inquadrate da lesene di ordine corinzio e al centro tabula ansata priva di 
                iscrizione o raffigurazione. Il coperchio, coerente per tipologia, con tabula ansata anepigrafe è probabilmente non pertinente. 
                Si tratta di un tipico prodotto di officine marmorarie urbane del III-IV d.C.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/3-Sala_III.jpg" alt="Sala III">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleIt from '@/components/TopbarSaleIt.vue';

    export default {
        name: 'Sala3It',
        components: {
            TopbarSaleIt
        }
    }
</script>