import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ItHome from '../views/ItHome.vue'
import EnHome from '../views/EnHome.vue'
import EsHome from '../views/EsHome.vue'
import FrHome from '../views/FrHome.vue'
import ItMuseo from '../views/ItMuseo.vue'
import EnMuseum from '../views/EnMuseum.vue'
import EsMuseo from '../views/EsMuseo.vue'
import FrMusee from '../views/FrMusee.vue'
import Sala1It from '../views/sale/Sala1It.vue'
import Sala2It from '../views/sale/Sala2It.vue'
import Sala3It from '../views/sale/Sala3It.vue'
import Sala4It from '../views/sale/Sala4It.vue'
import Sala5It from '../views/sale/Sala5It.vue'
import Sala6It from '../views/sale/Sala6It.vue'
import Sala7It from '../views/sale/Sala7It.vue'
import Sala8It from '../views/sale/Sala8It.vue'
import Sala9It from '../views/sale/Sala9It.vue'
import Hall1En from '../views/halls/Hall1En.vue'
import Hall2En from '../views/halls/Hall2En.vue'
import Hall3En from '../views/halls/Hall3En.vue'
import Hall4En from '../views/halls/Hall4En.vue'
import Hall5En from '../views/halls/Hall5En.vue'
import Hall6En from '../views/halls/Hall6En.vue'
import Hall7En from '../views/halls/Hall7En.vue'
import Hall8En from '../views/halls/Hall8En.vue'
import Hall9En from '../views/halls/Hall9En.vue'
import Sala1Es from '../views/salas/Sala1Es.vue'
import Sala2Es from '../views/salas/Sala2Es.vue'
import Sala3Es from '../views/salas/Sala3Es.vue'
import Sala4Es from '../views/salas/Sala4Es.vue'
import Sala5Es from '../views/salas/Sala5Es.vue'
import Sala6Es from '../views/salas/Sala6Es.vue'
import Sala7Es from '../views/salas/Sala7Es.vue'
import Sala8Es from '../views/salas/Sala8Es.vue'
import Sala9Es from '../views/salas/Sala9Es.vue'
import Salle1Fr from '../views/salles/Salle1Fr.vue'
import Salle2Fr from '../views/salles/Salle2Fr.vue'
import Salle3Fr from '../views/salles/Salle3Fr.vue'
import Salle4Fr from '../views/salles/Salle4Fr.vue'
import Salle5Fr from '../views/salles/Salle5Fr.vue'
import Salle6Fr from '../views/salles/Salle6Fr.vue'
import Salle7Fr from '../views/salles/Salle7Fr.vue'
import Salle8Fr from '../views/salles/Salle8Fr.vue'
import Salle9Fr from '../views/salles/Salle9Fr.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/it',
    name: 'ItHome',
    component: ItHome
  },
  {
    path: '/en',
    name: 'EnHome',
    component: EnHome
  },
  {
    path: '/es',
    name: 'EsHome',
    component: EsHome
  },{
    path: '/fr',
    name: 'FrHome',
    component: FrHome
  },
  {
    path: '/it/sala-1',
    name: 'Sala1It',
    component: Sala1It
  },
  {
    path: '/it/sala-2',
    name: 'Sala2It',
    component: Sala2It
  },
  {
    path: '/it/sala-3',
    name: 'Sala3It',
    component: Sala3It
  },
  {
    path: '/it/sala-4',
    name: 'Sala4It',
    component: Sala4It
  },
  {
    path: '/it/sala-5',
    name: 'Sala5It',
    component: Sala5It
  },
  {
    path: '/it/sala-6',
    name: 'Sala6It',
    component: Sala6It
  },
  {
    path: '/it/sala-7',
    name: 'Sala7It',
    component: Sala7It
  },
  {
    path: '/it/sala-8',
    name: 'Sala8It',
    component: Sala8It
  },
  {
    path: '/it/sala-9',
    name: 'Sala9It',
    component: Sala9It
  },
  {
    path: '/it/museo',
    name: 'ItMuseo',
    component: ItMuseo
  },
  {
    path: '/en/museum',
    name: 'EnMuseum',
    component: EnMuseum
  },
  {
    path: '/es/museo',
    name: 'EsMuseo',
    component: EsMuseo
  },
  {
    path: '/en/hall-1',
    name: 'Hall1En',
    component: Hall1En
  },
  {
    path: '/en/hall-2',
    name: 'Hall2En',
    component: Hall2En
  },
  {
    path: '/en/hall-3',
    name: 'Hall3En',
    component: Hall3En
  },
  {
    path: '/en/hall-4',
    name: 'Hall4En',
    component: Hall4En
  },
  {
    path: '/en/hall-5',
    name: 'Hall5En',
    component: Hall5En
  },
  {
    path: '/en/hall-6',
    name: 'Hall6En',
    component: Hall6En
  },
  {
    path: '/en/hall-7',
    name: 'Hall7En',
    component: Hall7En
  },
  {
    path: '/en/hall-8',
    name: 'Hall8En',
    component: Hall8En
  },
  {
    path: '/en/hall-9',
    name: 'Hall9En',
    component: Hall9En
  },
  {
    path: '/es/sala-1',
    name: 'Sala1Es',
    component: Sala1Es
  },
  {
    path: '/es/sala-2',
    name: 'Sala2Es',
    component: Sala2Es
  },
  {
    path: '/es/sala-3',
    name: 'Sala3Es',
    component: Sala3Es
  },
  {
    path: '/es/sala-4',
    name: 'Sala4Es',
    component: Sala4Es
  },
  {
    path: '/es/sala-5',
    name: 'Sala5Es',
    component: Sala5Es
  },
  {
    path: '/es/sala-6',
    name: 'Sala6Es',
    component: Sala6Es
  },
  {
    path: '/es/sala-7',
    name: 'Sala7Es',
    component: Sala7Es
  },
  {
    path: '/es/sala-8',
    name: 'Sala8Es',
    component: Sala8Es
  },
  {
    path: '/es/sala-9',
    name: 'Sala9Es',
    component: Sala9Es
  },
  {
    path: '/en/museum',
    name: 'EnMuseum',
    component: EnMuseum
  },
  {
    path: '/es/museo',
    name: 'EsMuseo',
    component: EsMuseo
  },
  {
    path: '/fr/musee',
    name: 'FrMusee',
    component: FrMusee
  },
  {
    path: '/fr/salle-1',
    name: 'Salle1Fr',
    component: Salle1Fr
  },
  {
    path: '/fr/salle-2',
    name: 'Salle2Fr',
    component: Salle2Fr
  },
  {
    path: '/fr/salle-3',
    name: 'Salle3Fr',
    component: Salle3Fr
  },
  {
    path: '/fr/salle-4',
    name: 'Salle4Fr',
    component: Salle4Fr
  },
  {
    path: '/fr/salle-5',
    name: 'Salle5Fr',
    component: Salle5Fr
  },
  {
    path: '/fr/salle-6',
    name: 'Salle6Fr',
    component: Salle6Fr
  },
  {
    path: '/fr/salle-7',
    name: 'Salle7Fr',
    component: Salle7Fr
  },
  {
    path: '/fr/salle-8',
    name: 'Salle8Fr',
    component: Salle8Fr
  },
  {
    path: '/fr/salle-9',
    name: 'Salle9Fr',
    component: Salle9Fr
  }
  
]

const router = createRouter({
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { top:0 };
  },
  history: createWebHashHistory(),
  routes
})

export default router
