<template>
    <div>
        <TopbarSaleEn titolo="HALL IX"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/9-Sala_IX.jpg" alt="Sala IX">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">HALL IX <br> HALL OF COINS</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/hall_nine.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Listen to the sound track</i></p>
            <p class="desContenutoSala">
                In the small medal cabinet there is a small collection of Roman coins of the Republican and Imperial age which can
             be observed on both sides.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/9-Sala_IX.jpg" alt="Sala IX">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'Hall9En',
        components: {
            TopbarSaleEn
        }
    }
</script>