<template>
  <div class="topbar">
    <img id="logoScritta" src="@/assets/logo_scritta.png" alt="Logo">
  </div>

  <div class="torna">
    <router-link to="/" id="tornaBtn">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
            <path id="Poligono_1" data-name="Poligono 1" d="M4,0,8,8H0Z" transform="translate(0 8) rotate(-90)" fill="#000000"/>
        </svg>
        <p>Regresar</p>
    </router-link>    
  </div>
</template>

<script>
export default {
  name: 'TopbarInternaEs'
}
</script>
