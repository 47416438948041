<template>
    <div>
        <TopbarSaleEn titolo="THE MUSEUM"/> 

        

        <div class="contenutoSala">          
            <img id="ingresso" src="@/assets/pianta_museale.png" alt="Pianta museale">
            <h3 class="titoloContenutoSala">VILLA ADELE formerly VILLA PIA</h3>
            
            <audio controls class="audio">
                <source src="@/assets/audio/en/museum.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Listen to the audio track</i></p>
            <p class="desContenutoSala">In 1615, Cardinal Bartolomeo Cesi of Acquasparta, general treasurer of the Apostolic Chamber, 
                had a residential villa built in Anzio at the place known as the "Torre d'Imperio" (Soffredini 1879, 115). In 1648, the 
                villa was sold by his nephew, Giovanni Federico Cesi, to Prince Camillo Pamphilj, nephew of Pope Innocent X (1644-1655), 
                who enlarged and embellished the buildings. In 1760 ownership of the villa passed to the Doria Pamphilj branch, which was 
                succeeded first by the Borghese family in 1834 and then by the Aldobrandini family in 1851. After the partial dismemberment 
                of the estate in the first decade of the twentieth century, the casino was converted into a hotel, the "Excelsior Palace Hotel."
                To this period dates the final arrangement of the main elevation, with a three-arched portico providing access to the so-called 
                Sala delle Conchiglie, with an octagonal plan. Beginning in 1964, the entire grounds became the property of the Municipality of 
                Anzio. Currently, the park is for public use and the main casino houses the Civic Archaeological Museum, since 2002, Museo 
                dello Sbarco, since 1994, and the municipal library.
            </p>

            <div class="image-container">
                <img src="@/assets/ingresso.jpg" alt="Ingresso museo">
                <img src="@/assets/MUS_00001.jpg" alt="Museo">
                <img src="@/assets/MUS_00002.jpg" alt="Museo">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'EnMuseum',
        components: {
            TopbarSaleEn
        }
    }
</script>