<template>
    <div>
        <TopbarSaleFr titolo="SALLE II"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/2-sala_II.jpg" alt="Salle II">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALLE II</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_2.1.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">À gauche, la vitrine 3 est consacrée aux témoignages archéologiques de l'époque républicaine moyenne et 
                tardive (IVe-IIe siècle av. J.-C.). Il s'agit, pour la plupart, de statuettes et de pièces anatomiques à caractère votif. 
                On remarque en particulier un fragment de poterie vernissée noire, avec des lettres peintes correspondant à la partie terminale 
                d'un théonyme, peut-être Fortuna, la principale divinité vénérée à Anzio, sous la forme Fortune, ou "alla Fortuna" ou "de la 
                Fortune".
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_3.jpg" alt="Vitrine salle II">
            </div>
            
            <p class="desContenutoSala">
                On notera en particulier l'unique <i>Guttus</i>, une ancienne bouteille en poterie à glaçure noire en forme d'éléphant, montée par un cornac, 
                datant du IIIe siècle avant Jésus-Christ. Le vase a été trouvé à Anzio en 1954 lors de la construction d'un immeuble dans la Via Roma. 
                Il est <i>unique</i> en son genre dans la région italienne. L'attention portée aux détails et le mouvement de l'animal témoignent d'une 
                connaissance directe du sujet par le céramiste, probablement apulien. Dans l'espace entre les pattes avant et arrière, une rangée 
                d'hommes armés de lances et de boucliers est insérée en relief, avançant sur les côtés de l'éléphant, qualifiant ainsi sa fonction 
                militaire.
                <br><br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_guttus.jpg" alt="Salle II">
            </div>
            

            <h3 class="titoloContenutoSala">LA JEUNE FILLE D'ANZIO</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_2.2.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">
                La statue a été retrouvée le 20 décembre 1878 dans une niche de la villa impériale à la suite d'une forte tempête 
                maritime. D'abord placée dans le vestibule de la Villa Sarsina, la résidence des princes Aldobrandini, parce qu'elle 
                avait été trouvée sur leur propriété, elle fut ensuite achetée en 1909 par l'État italien pour la somme considérable, 
                pour l'époque, de 450 000 lires. La statue représente un personnage féminin, à peine plus qu'une adolescente, vêtue 
                d'un chiton et d'un manteau assez lourd, porté noué sur les hanches ; elle porte de simples sandales et a les cheveux 
                relevés dans une coiffure inhabituelle. Ses mains tiennent un plateau sur lequel sont posés quelques objets: 
                une brindille et des baies de laurier, un bandage sacrificiel, deux pattes de lion attribuables à un récipient. 
                Certains détails techniques et le remaniement du socle de la base ont permis d'émettre l'hypothèse qu'il s'agissait 
                d'un groupe statuaire, peut-être lié à la sphère dionysiaque. Il s'agirait d'un original hellénistique datable du 
                IIIe siècle av.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/fanciulla.jpg" alt="Statue de la jeune fille d'Anzio">
            </div>

            <h3 class="titoloContenutoSala">L'ÂGE ROMAIN</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_2.3.mp3" type="audio/mpeg">
            </audio>
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">
                Sur l'étagère de droite se trouve une partie d'un relief, datable du début du IIIe siècle, représentant une scène de sacrifice avec, 
                à l'arrière-plan, une architecture sacrée avec des chapiteaux de type égyptien. Sur le mur, à droite, un fragment de sarcophage 
                d'époque impériale avec une représentation d'un cheval tirant un char. Sur la gauche, deux inscriptions sépulcrales: en haut : 
                "Clodia Ge(mella), affranchie de Gaius, faite pour elle-même et sa sœur Clodia Gemella, affranchie de Gaius" (fin du Ier siècle-début 
                du IIe siècle apr. J.-C.) ; en bas : Aux mains des dieux de Successus, esclave impérial (fin du Ier siècle apr. J.-C.).
            </p>

            <div class="image-container">
                <img src="@/assets/sale/2-sala_II.jpg" alt="Salle II">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'Salle2Fr',
        components: {
            TopbarSaleFr
        }
    }
</script>