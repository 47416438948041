<template>
    <div>
        <TopbarSaleFr titolo="SALLE IV"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/4-Sala_IV.jpg" alt="Salle IV">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALLE IV</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_4.mp3" type="audio/mpeg">
            </audio>            
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">
                La salle présente des objets liés à différents aspects de la vie quotidienne à Anzio à l'époque romaine impériale.
                <br><br>
                Dans la vitrine de gauche sont exposés quelques fragments de briques architecturales du Ier siècle après J.-C., destinées à recouvrir 
                les toits et les architraves de bâtiments ou de tombes monumentales : une dalle avec une tête de Gorgone ; un fragment de dalle 
                architecturale de parement avec une partie d'un personnage masculin et un bouclier, présentant encore des traces de la couleur 
                d'origine ; une palmette antéfixe. Quelques fragments d'enduit peint se rapportent à la même zone.<br>
                Le grand mortier en terre cuite portant l'estampille de la fabrique <i>Augustalis Saturnini</i> du Ier siècle après J.-C. 
                peut être attribué à la cuisine.

            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_4.jpg" alt="Vetrina sala IV">
            </div>      
            
            <p class="desContenutoSala">
                La vitrine de droite présente : sur l'étagère supérieure, un miroir en plomb du Ier siècle après J.-C. et plusieurs vases en bronze 
                de l'époque impériale. Deux cruches et une situle avec des applications de masque de Silène.<br>
                Sur l'étagère du milieu se trouvent plusieurs fragments de verre à vitre, des pièces de monnaie en bronze, dont deux sesterces de 
                l'époque impériale, et quelques pièces spéciales :<br><br>
                - deux <i>tabellae defixionum</i>, c'est-à-dire des tablettes de plomb sur lesquelles étaient gravées des phrases insultantes et de 
                véritables malédictions adressées à une personne haïe ou ennemie. Ces objets étaient typiques de la sphère compétitive liée aux jeux 
                du cirque qui souhaitaient souvent les pires maux aux chars ou même aux chevaux des équipes adverses;<br>
                - un autre objet particulier est le <i>signaculum</i> ou timbre de bronze. Il servait à laisser une empreinte avant la cuisson, véritable 
                poinçon sur certains produits alimentaires, comme le pain;<br>
                - enfin, une partie d'un plat d'une production définie comme scellée en Gaule du Sud, datée du Ier siècle après J.-C., d'un type assez 
                rare, dont les surfaces sont recouvertes d'un vernis qui donne un effet chromatique particulier appelé marbré.<br><br>
                Sur l'étagère la plus basse se trouve un morceau de tuyau, une fistule en plomb, avec l'inscription en relief du nom du <i>plumbarius</i> qui 
                l'a fabriquée à l'époque impériale: <i>Alexander fecit</i>.      
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_5.jpg" alt="Vetrina sala IV">
            </div> 
        </div>       
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'Salle4Fr',
        components: {
            TopbarSaleFr
        }
    }
</script>