<template>
    <div>
        <TopbarSaleEn titolo="HALL I"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/1-sala_I.jpg" alt="Hall I">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">HALL I <br>THE ANCIENT ORIGINS OF THE CITY - ANTIUM LATINA (MATERIAL FROM THE X-IXTH CENTURY B.C.)</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/en/hall_one.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Listen to the audio track</i></p>
            <p class="desContenutoSala">
                The first evidence of stable occupation of the Anzio area by the Latins dates back to the 10th century B.C.; 
                between the end of the 6th and the beginning of the 5th century B.C., southern Latium was invaded by the Volscians, 
                a population of Osco-Umbrian stock: Anzio, immediately conquered, became a fearsome adversary of Rome in the 5th century. 
                The Volscian power finally wanes in the second half of the 4th century BC. <br>
                From this period onwards, Roman dominance asserted itself: Anzio, like the rest of Latium and northern Campania, was 
                conquered by Rome in 338 BC. Having lost its autonomy, the city welcomed the dedication of a colony of 300 citizens with 
                full Roman citizenship rights. Pecco's small nucleus of colonists was to defend the city's port and the road leading to 
                the Alban Hills.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/1-sala_I.jpg" alt="Sala I">
                <img src="@/assets/sale/vetrina_1.jpg" alt="Vetrina sala I">
                <img src="@/assets/sale/vetrina_2.jpg" alt="Vetrina sala II">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleEn from '@/components/TopbarSaleEn.vue';

    export default {
        name: 'Hall1En',
        components: {
            TopbarSaleEn
        }
    }
</script>