<template>
    <div>
        <TopbarSaleFr titolo="SALLE I"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/1-sala_I.jpg" alt="Salle I">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALLE I <br>LES ORIGINES ANCIENNES DE LA VILLE - ANTIUM LATINA (MATÉRIEL DU X-IXE SIÈCLE AV. J.-C.)</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_1.mp3" type="audio/mpeg">
            </audio>

            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">Les premiers témoignages d'une occupation stable de la région d'Anzio par les Latins remontent 
                au Xe siècle avant J.-C. ; entre la fin du VIe et le début du Ve siècle avant J.-C., le sud du Latium est envahi par 
                les Volsques, une population de souche osco-ombrienne : Anzio, immédiatement conquise, devient un adversaire redoutable 
                de Rome au Ve siècle. La puissance des Volsques s'affaiblit finalement dans la seconde moitié du IVe siècle av.<br>
                À partir de cette période, la domination romaine s'affirme : Anzio, comme le reste du Latium et le nord de la Campanie, 
                est conquise par Rome en 338 av. Ayant perdu son autonomie, la ville a accueilli une colonie de 300 citoyens jouissant de
                 tous les droits de citoyenneté romaine. Le petit noyau de colons de Pecco devait défendre le port de la ville et la 
                 route menant aux monts Alban.<br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/1-sala_I.jpg" alt="Sala I">
            </div>     
            
            <p class="desContenutoSala">
                Dans la vitrine de gauche (vitrine 1) sont exposés des récipients en céramique et des objets en bronze datés entre le Xe et le 
                VIIe siècle avant J.-C. : des coupes, des ollae et des cruches à empâtement brun grossier et des fibules de navette en bronze. <br>
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_1.jpg" alt="Vetrina sala I">
            </div>

            <p class="desContenutoSala">
                Dans la vitrine de droite (vitrine 2), nous trouvons du matériel probablement trouvé dans un dépôt votif de la période 
                médio-républicaine (4e-3e s. av. J.-C.), c'est-à-dire du matériel utilisé ou offert dans un lieu de culte. Il s'agit de 
                petites cruches à empâtement léger, de bols en céramique à glaçure noire et de quelques récipients à glaçure noire surchargée, 
                provenant en grande partie d'Étrurie.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/vetrina_2.jpg" alt="Vetrina sala II">
            </div>
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'Salle1Fr',
        components: {
            TopbarSaleFr
        }
    }
</script>