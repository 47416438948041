<template>
    <div>
        <TopbarSaleEs titolo="SALA VIII"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/8-Sala_VIII.jpg" alt="Sala VIII">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALA VIII</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/es/sala_ocho.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">La sala exhibe documentos, dibujos y fotografías que ilustran la riqueza de la
             documentación existente sobre las antigüedades de Anzio. La verdadera "Suerte de Anzio".
             <br><br>
             En el panel de la izquierda hay documentos e ideas relacionadas con hallazgos importantes o sitios arqueológicos de Anzio y dei
             alrededores. En particular, algunos hechos relacionados con la compra de la Fanciulla d'Anzio por parte del Estado italiano con la primera
             página de un periódico de la época; documentos de archivo relativos a los proyectos de construcción de balizas y faroles sobre las ruinas del puerto
             neroniano; las fotografías de la torre Astura transformadas en el plató de la película Cleopatra, con Richard Burton y Liz Taylor.
             <br><br>
             En la pared cercana está la fotografía tomada por los Ingenieros Civiles en 1909 para adjuntar a la evaluación de la niña de Anzio
             donde se informa el punto exacto del descubrimiento.
             <br><br>
             En el panel derecho se muestran algunos documentos, dibujos y fotografías relativos a la historia de los descubrimientos realizados
             en la zona de la villa imperial, desde los dibujos publicados por Volpi y otros estudiosos durante el siglo XVIII, hasta los relieves
             de Rodolfo Lanciani, hasta los descubrimientos realizados en las primeras décadas del siglo XX, como el calendario precesárico
             y la estatua de una amazona a caballo que arrolla a un bárbaro que actualmente se conserva en Roma en el Museo Nacional Romano.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/8-Sala_VIII.jpg" alt="Sala VIII">
            </div>
            
            
            <h3 class="titoloContenutoSala">ARTEFACTOS</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/sala_otto_reperti.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Escuchar la pista de audio</i></p>
            <p class="desContenutoSala">Algunas exhibiciones también se exhiben en la sala: <br>
             1) Parte de un brazo en mármol blanco, fechable en la época imperial y relacionado con una estatua de tamaño mayor que el natural; <br>
             2) pretoriano imperial; <br>
             3-4) sobre soportes celestes: inscripciones sepulcrales fragmentarias; <br>
             5-6) en el suelo hay otra inscripción sepulcral, mutilada, y parte de una inscripción significativa que informa de la construcción
             de una obra pública, donde se menciona una vía tecta, es decir cubierta, y el foro. Desgraciadamente, las circunstancias y el lugar de la
             los resultados se basan en información incierta. Además, la pieza seguramente fue resecada y reutilizada como umbral ya en
             antiguo moviéndolo del lugar donde se colocó originalmente.
            </p>
        </div>      
        
                
    </div>
</template>

<script>
    import TopbarSaleEs from '@/components/TopbarSaleEs.vue';

    export default {
        name: 'Sala8Es',
        components: {
            TopbarSaleEs
        }
    }
</script>