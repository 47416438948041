<template>
    <div>
        <TopbarSaleFr titolo="SALLE IX"/> 

        <div class="imgAntSala">
            <img src="@/assets/sale/9-Sala_IX.jpg" alt="Salle IX">
        </div>

        <div class="contenutoSala">           
            <h3 class="titoloContenutoSala">SALLE IX <br> SALLE DES COINS</h3>
            <audio controls class="audio">
                <source src="@/assets/audio/fr/Sala_9.mp3" type="audio/mpeg">
            </audio> 
            <p class="traccia"><i>Écouter la piste audio</i></p>
            <p class="desContenutoSala">
                Une petite collection de pièces de monnaie romaines des périodes républicaine et impériale est exposée dans le petit medagliere.
            </p>

            <div class="image-container">
                <img src="@/assets/sale/9-Sala_IX.jpg" alt="Salle IX">
            </div>            
        </div>

        
        
                
    </div>
</template>

<script>
    import TopbarSaleFr from '@/components/TopbarSaleFr.vue';

    export default {
        name: 'Salle9Fr',
        components: {
            TopbarSaleFr
        }
    }
</script>