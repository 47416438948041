<template>

  <div id="application">

    <TopBar/>

    <div class="home">
      <img id="logo" src="@/assets/logo_homeApp.png" alt="Logo Museo">

      <router-link to="/it"><p class="btnLingua">Italiano</p></router-link>
      <router-link to="/en"><p class="btnLingua">English</p></router-link>
      <router-link to="/fr"><p class="btnLingua">Français</p></router-link>
      <router-link to="/es"><p class="btnLingua">Español</p></router-link>
    </div>

    <div id="footer">

      <div class="regione">
        <img id="logoRegione" src="@/assets/logo_regione.jpg" alt="Regione Lazio">
        <p id="didRegione">Progetto realizzato con il sostegno della Regione Lazio per Biblioteche, Musei e Archivi 
          Piano annuale 2022, L.R. 24/2019</p>
      </div>

      <div class="regione">
        <img id="logoAnzio" src="@/assets/logo_anzio.png" alt="Logo Città di Anzio">
      </div>

    </div>

  </div>

  <div id="alert">    

      <img src="@/assets/logo_homeApp.png" alt="Logo">
      <h2>Per consultare la guida utilizza un device con uno schermo largo massimo 835px.</h2>
      <h4>To consult the guide, use a device with a maximum screen size of 835px.</h4>
      <h4>Para consultar la guía, utilice un dispositivo con un tamaño de pantalla máximo de 835 px.</h4>
      <h4>Pour consulter le guide, utilisez un appareil avec une largeur d'écran maximale de 835px.</h4>
  </div> 

  

</template>

<script>
// @ is an alias to /src
import TopBar from '@/components/TopBar.vue';

export default {
  name: 'HomeView',
  components: {
    TopBar
  }
}
</script>
